import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { LocalStoreService } from '../services/local-store.service';
import { MessageService } from '../../@pages/components/message/message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private store : LocalStoreService,
    private messageService : MessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.auth.signout();
        debugger;
      }

      if (err.status === 403) {
        this.messageService.error("Unauthorized.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }

      if (err.status === 404) {
        this.messageService.error("Not Found.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }

      if (err.status === 429) {
        this.messageService.error("Too many Requests.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }

      if (err.status === 500) {
        this.messageService.error("Something went wrong.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
      }

      // const error = err.error.message || err.statusText;
      //console.log(err);
      if(err.error){
        return throwError(err.error.errors);
      }
      else{
        return throwError("Error.");
      }
    }));
  }
}