import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.stanthonyUrl}/quote`,{params:searchParams});
    }

    get(Id){
      return this.http.get(`${environment.stanthonyUrl}/quote/`+Id);
    }

    edit(Id,formData){
      return this.http.put(`${environment.stanthonyUrl}/quote/`+Id,formData);
    }

    create(formData){
      return this.http.post(`${environment.stanthonyUrl}/quote`,formData);
    }

    delete(Id){
      return this.http.delete(`${environment.stanthonyUrl}/quote/`+Id);
    }

	send(Id, formData){
		return this.http.get(`${environment.stanthonyUrl}/quote/`+Id+ '/send', formData);
	}

	approve(quote, formData){
		return this.http.post(`${environment.stanthonyUrl}/quote/`+quote+ 'approve', formData);
	}

	cancel(quote, formData){
		return this.http.post(`${environment.stanthonyUrl}/quote/`+quote+ 'cancel', formData);
	}

	complete(quote, formData){
		return this.http.post(`${environment.stanthonyUrl}/quote/`+quote+ 'complete', formData);
	}

	clone(quote){
		return this.http.post(`${environment.stanthonyUrl}/quote/`+quote+ '/clone', {});
	}

	getAllstatus(searchParams){
		return this.http.get(`${environment.stanthonyUrl}/quote/status`,{params:searchParams});
	}

	calculatePrice(formData) {
		return this.http.post(`${environment.stanthonyUrl}/quote/calculate`,formData);
	}
}
