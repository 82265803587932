import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { HttpClientModule } from '@angular/common/http';

import { SecondarySidebarComponent } from './secondary-sidebar/secondary-sidebar.component';

import { QuickviewService } from './quickview/quickview.service';
import { ModalModule, TypeaheadModule } from 'ngx-bootstrap';

import { ParallaxDirective } from './parallax/parallax.directive';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormGroupDefaultDirective } from './forms/form-group-default.directive';
import { ViewDirective } from './view/view.directive';

import { pgCollapseModule } from './collapse/collapse.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ContainerComponent } from './container/container.component';
import { pageContainer } from './container/page-container.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MenuAltComponent } from './menu/menu-alt.component';
import { MenuIconComponent } from './menu/menu-icon.component';

import { ListItemComponent } from './list-view/list-item/list-item.component';
import { ListViewContainerComponent } from './list-view/list-view-container/list-view-container.component';
import { pgRetinaDirective } from './retina/retina.directive';
import { DotMenuModule } from '../../view/components/dot-menu/dot-menu.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ButtonLoadingModule } from '../../view/components/btn-loading/btn-loading.module';
import { pgSelectModule } from './select/select.module';
import { pgDatePickerModule } from './datepicker/datepicker.module';
import { NguiMapModule } from '@ngui/map';
import { environment } from '../../../environments/environment';
import { ProgressModule } from './progress/progress.module';
// import { PaymentComponent } from '../../view/components/payment/payment.component';
// import { NgxStripeModule } from 'ngx-stripe';
// import { SubscriptionModalComponent } from '../../view/components/subscription-modal/subscription-modal.component';
// import { CardModalComponent } from '../../view/components/add-card/add-card.component';
import { MessageModule } from './message/message.module';
import { TextMaskModule } from 'angular2-text-mask';
import { pgSwitchModule } from './switch/switch.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingModule } from '../../view/components/loader/loader.module';
import { PaginatedSelectModule } from '../../view/components/paginated-select/paginated-select.module';
import { PhoneInputModule } from '../../view/components/phone-input/phone-input.module';
import { Utils } from '../../shared/services/utils.service';
import { TableModule } from '../../view/components/codeblack-table/codeblack-table.module';
import { MainHeaderModule } from '../../view/components/main-header/main-header.module';
import { MenuSelectionComponent } from '../../view/menu/menu.component';
import { EmployeeListComponent } from '../../view/employee/employee-list/employee-list.component';
import { EmployeeDetailComponent } from '../../view/employee/employee-detail/employee-detail.component';
import { InputModule } from '../../view/components/input/input.module';
import { GroupListComponent } from '../../view/user/groups/groups-list/groups-list.component';
import { GroupDetailComponent } from '../../view/user/groups/groups-detail/groups-detail.component';
import { DeviceListComponent } from '../../view/device/device-list/device-list.component';
import { DeviceDetailComponent } from '../../view/device/device-detail/device-detail.component';
import { RouteListComponent } from '../../view/route/route-list/route-list.component';
import { RouteDetailComponent } from '../../view/route/route-detail/route-detail.component';
import { POITypeDetailComponent } from '../../view/route/poi-type/poi-type-detail/poi-type-detail.component';
import { POITypeListComponent } from '../../view/route/poi-type/poi-type-list/poi-type-list.component';
import { AdvertListComponent } from '../../view/advert/advert-list/advert-list.component';
import { AdvertDetailComponent } from '../../view/advert/advert-detail/advert-detail.component';
import { CaminoListComponent } from '../../view/camino/camino-list/camino-list.component';
import { CaminoDetailComponent } from '../../view/camino/camino-detail/camino-detail.component';
import { pgUploadModule } from './upload/upload.module';
import { PilgrimGuideDetailComponent } from '../../view/pilgrim-guide/pilgrim-guide-detail/pilgrim-guide-detail.component';
import { PilgrimGuideListComponent } from '../../view/pilgrim-guide/pilgrim-guide-list/pilgrim-guide-list.component';
import { StaticPageListComponent } from '../../view/static-pages/static-pages-list/static-pages-list.component';
import { StaticPageDetailComponent } from '../../view/static-pages/static-pages-detail/static-pages-detail.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { CertificateListComponent } from '../../view/certificate/certificate-list/certificate-list.component';
import { CertificateDetailComponent } from '../../view/certificate/certificate-detail/certificate-detail.component';
import { FurnitureListComponent } from '../../view/furniture/furniture-list/furniture-list.component';
import { FurnitureDetailComponent } from '../../view/furniture/furniture-detail/furniture-detail.component';
import { StockDetailComponent } from '../../view/stock/stock-detail/stock-detail.component';
import { StockListComponent } from '../../view/stock/stock-list/stock-list.component';
import { WorkTimeDetailComponent } from '../../view/worktime/worktime-detail/worktime-detail.component';
import { WorkTimeListComponent } from '../../view/worktime/worktime-list/worktime-list.component';
import { QuoteListComponent } from '../../view/quote/quote-list/quote-list.component';
import { QuoteDetailComponent } from '../../view/quote/quote-detail/quote-detail.component';
import { UnitListComponent } from '../../view/unit/unit-list/unit-list.component';
import { UnitDetailComponent } from '../../view/unit/unit-detail/unit-detail.component';
import { TypeListComponent } from '../../view/stock/type/type-list/type-list.component';
import { TypeDetailComponent } from '../../view/stock/type/type-detail/type-detail.component';
import { PartListComponent } from '../../view/part/part-list/part-list.component';
import { PartDetailComponent } from '../../view/part/part-detail/part-detail.component';
import { SettingsDetailComponent } from '../../view/settings/settings-detail/settings-detail.component';
import { PartCategoryDetailComponent } from '../../view/part/category/category-detail/category-detail.component';
import { PartCategoryListComponent } from '../../view/part/category/category-list/category-list.component';
import { FurnitureCategoryListComponent } from '../../view/furniture/category/category-list/category-list.component';
import { FurnitureCategoryDetailComponent } from '../../view/furniture/category/category-detail/category-detail.component';
import { SettingsListComponent } from '../../view/settings/settings-list/settings-list.component';
import { GroupPartsItemComponent } from './group-parts-item/group-parts-item.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@Pipe({
  name: 'numberformat'
})
export class NumberFormatPipe implements PipeTransform {

  constructor() { }
  transform(val) {
    return Utils.formatNumbersToFixedWithCommas(val);
  }

}

@NgModule({
  imports: [
    MessageModule,
    TextMaskModule,
    pgSwitchModule,
    SignaturePadModule,
    CommonModule,
    ObserversModule,
    TypeaheadModule.forRoot(),
    ProgressModule,
    PerfectScrollbarModule,
    RouterModule,
    DotMenuModule,
    PaginatedSelectModule,
    //NgxStripeModule.forRoot("pk_test_51I69xgJ8aQygRtovt6v3WzpsJFT9vCzWN18v6kmzxlCUgGs7fCkh0N8SRF2mhpYyS1bbqlBV0yrI3J3LWkvhqfTr00x5b4J9gd"),
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ModalModule,
    ButtonLoadingModule,
    LoadingModule,
    NgxPermissionsModule,
    PhoneInputModule,
    InputModule,
    pgSelectModule,
    pgDatePickerModule,
    pgUploadModule,
    TableModule,
    EditorModule,
    MainHeaderModule],
    //NguiMapModule.forRoot({ apiUrl: environment.mapUrl })],
  declarations: [
    SecondarySidebarComponent,
    ParallaxDirective,
    BreadcrumbComponent,
    FormGroupDefaultDirective,
    ViewDirective,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective,
    MenuSelectionComponent,
    SafePipe,
    NumberFormatPipe,
    EmployeeListComponent,
    EmployeeDetailComponent,
    GroupListComponent,
    GroupDetailComponent,
    DeviceListComponent,
    DeviceDetailComponent,
    RouteListComponent,
    RouteDetailComponent,
    POITypeDetailComponent,
    POITypeListComponent,
    AdvertListComponent,
    AdvertDetailComponent,
    CaminoListComponent,
    CaminoDetailComponent,
    PilgrimGuideDetailComponent,
    PilgrimGuideListComponent,
    StaticPageListComponent,
    StaticPageDetailComponent,
    CertificateListComponent,
    CertificateDetailComponent,
	FurnitureListComponent,
    FurnitureDetailComponent,
	PartListComponent,
    PartDetailComponent,
    StockDetailComponent,
	StockListComponent,
	WorkTimeDetailComponent,
	WorkTimeListComponent,
	QuoteListComponent,
    QuoteDetailComponent,
	TypeListComponent,
    TypeDetailComponent,
	PartCategoryListComponent,
	PartCategoryDetailComponent,
	FurnitureCategoryListComponent,
	FurnitureCategoryDetailComponent,
	UnitListComponent,
    UnitDetailComponent,
	SettingsDetailComponent,
	SettingsListComponent,
	GroupPartsItemComponent,
  ],
  exports: [
    MessageModule,
    TextMaskModule,
    pgSwitchModule,
    SignaturePadModule,
    SecondarySidebarComponent,
    ParallaxDirective,
    BreadcrumbComponent,
    FormGroupDefaultDirective,
    ViewDirective,
    pgCollapseModule,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    PhoneInputModule,
    InputModule,
    ListViewContainerComponent,
    pgRetinaDirective,
    DotMenuModule,
    LoadingModule,
    ButtonLoadingModule,
    NgxPermissionsModule,
    MenuSelectionComponent,
    SafePipe,
    NumberFormatPipe,
    PaginatedSelectModule,
    TableModule,
    MainHeaderModule,
    EmployeeListComponent,
    EmployeeDetailComponent,
    GroupListComponent,
    GroupDetailComponent,
    DeviceListComponent,
    DeviceDetailComponent,
    RouteListComponent,
    RouteDetailComponent,
    POITypeDetailComponent,
    POITypeListComponent,
    AdvertListComponent,
    AdvertDetailComponent,
    CaminoListComponent,
    CaminoDetailComponent,
    PilgrimGuideDetailComponent,
    PilgrimGuideListComponent,
    StaticPageListComponent,
    StaticPageDetailComponent,
    CertificateListComponent,
    CertificateDetailComponent,
	FurnitureListComponent,
    FurnitureDetailComponent,
	PartListComponent,
    PartDetailComponent,
	StockDetailComponent,
	StockListComponent,
	QuoteListComponent,
    QuoteDetailComponent,
	TypeListComponent,
    TypeDetailComponent,
	PartCategoryListComponent,
	PartCategoryDetailComponent,
	FurnitureCategoryListComponent,
	FurnitureCategoryDetailComponent,
	UnitListComponent,
    UnitDetailComponent,
	SettingsDetailComponent,
	SettingsListComponent,
  GroupPartsItemComponent
  ],
  providers: [
    QuickviewService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class SharedModule { }
