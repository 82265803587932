import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Location } from '@angular/common';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {

  @Input() module:any;
  @Input() menuItems:any;
  @Input() handleButtonPress:any;
  @Output() submitEvent:EventEmitter<any> = new EventEmitter<any>();
  @Input() isDetail:boolean = false;
  @Input() isSaved:boolean = false;
  @Input() external:boolean = false;
  @Input() hideCreate:boolean = false;
  @Input() newUrl:string = '';
  @Input() urlPdf:string = '';
  @Input() urlRfp:string = '';

  constructor(
    private permissionService : NgxPermissionsService,
    private router : Router,
    private _location: Location,
  ) { }
  
  create() {
    let _this = this;
	if(this.newUrl != '')
	{
		this.router.navigate([this.newUrl]);
	}
	else {
		this.router.navigate([this.module.new_name+'/new']);
	}
  }

  sendBack() {
    this._location.back();
  }

  onOpenDocument() {
    window.open(this.urlRfp, '_blank').focus();
  }

  onOpenQuoteDocument() {
    window.open(this.urlPdf, '_blank').focus();
  }

}