import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from '../environments/environment';
import { LocalStoreService } from './shared/services/local-store.service';

@Component({
  selector: 'app-root',
  // template: '<router-outlet></router-outlet>',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private authService : AuthService,
    private store : LocalStoreService){
    if(environment.production){
      this.startSentry();
    }
    //myUndefinedFunction();
  }

  getLogo() {
    return this.authService.getLogo();
  }

  getFavicon() {
    return this.authService.getFavicon();
  }

  isLoggedIn(){
    return this.authService.authenticated;
  }

  isAuthenticated() {
    if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
      return false;
    }
    return this.authService.authenticated;
  }

  startSentry(){
    Sentry.init({
      dsn: "https://2161d61a00324475bc489d67249655d0@o549696.ingest.sentry.io/5823640" ,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ["localhost", "https://yourserver.io/api"],
          routingInstrumentation: Sentry.routingInstrumentation,
    
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }

}
