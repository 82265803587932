import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { CustomerModel } from "./customer.model";

export class QuoteModel {

    id: number;
    quote_no:string;
	status: string;
    sub_total: string;
    vat_total: string;
    total_price: string;
    approved_at: string;
    created_at: string;
    created_at_formatted: string;
    sent_at: string;
	customer: any[];
	items: any[];


    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.quote_no = input.quote_no || '';
		this.customer = input.customer || [];
        this.sub_total = input.sub_total || '';
		this.total_price = input.total_price || '';
		this.vat_total = input.vat_total || '';
		this.status = input.status || '';
		this.approved_at = input.approved_at || '';
		this.created_at = input.created_at || '';
		if(this.created_at.length > 0)
		{
			this.created_at_formatted = moment(this.created_at).format('DD/MM/YY');
		}
		this.sent_at = input.sent_at || '';
		this.items = input.items || [];
    }	

	static getItemsFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();

		let psitemsFormArray = new FormArray([]);
		
		if (items.items) {
			for (let psitem of items.items) {
				psitemsFormArray.push(this.getPSItemsFormGroup(psitem));
			}
		}

		return fb.group({
			title: new FormControl(items ? items.title : ''),
			quantity: new FormControl(items ? items.quantity : ''),
			length: new FormControl(items ? items.length : ''),
			width: new FormControl(items ? items.width : ''),
			height: new FormControl(items ? items.height : ''),
			cost_price: new FormControl(items ? items.cost_price : ''),
			selling_price: new FormControl(items ? items.selling_price : ''),
			line_total: new FormControl(items ? items.line_total : ''),
			stock: new FormControl(items ? items.stock : null),
			part: new FormControl(items ? items.part : null),
			furniture: new FormControl(items ? items.furniture : null),
			image: new FormControl(items ? items.image : ''),
			id: new FormControl(items ? items.id : ''),
		});
	}

	static getPSItemsFormGroup(psitem?) {
		let fb: FormBuilder = new FormBuilder();

		let sitemsFormArray = new FormArray([]);
		
		if (psitem.items) {
			for (let sitem of psitem.items) {
				sitemsFormArray.push(this.getSItemsFormGroup(sitem));
			}
		}

		return fb.group({
			quantity: new FormControl(psitem ? psitem.quantity : ''),
			psitem: new FormControl(psitem ? psitem.id : ''),
			length: new FormControl(psitem ? psitem.length : ''),
			width: new FormControl(psitem ? psitem.width : ''),
			height: new FormControl(psitem ? psitem.height : ''),
			cost_price: new FormControl(psitem ? psitem.cost_price : ''),
			selling_price: new FormControl(psitem ? psitem.selling_price : ''),
			line_total: new FormControl(psitem ? psitem.line_total : ''),
			stock: new FormControl(psitem ? psitem.stock : {}),
			part: new FormControl(psitem ? psitem.part : {}),
			furniture: new FormControl(psitem ? psitem.furniture : {}),
			_items: new FormControl(psitem ? psitem.items : {}),
			sitems : sitemsFormArray
		});
	}

	static getSItemsFormGroup(sitem?) {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			quantity: new FormControl(sitem ? sitem.quantity : ''),
			sitem: new FormControl(sitem ? sitem.id : ''),
			length: new FormControl(sitem ? sitem.length : ''),
			width: new FormControl(sitem ? sitem.width : ''),
			height: new FormControl(sitem ? sitem.height : ''),
			cost_price: new FormControl(sitem ? sitem.cost_price : ''),
			selling_price: new FormControl(sitem ? sitem.selling_price : ''),
			line_total: new FormControl(sitem ? sitem.line_total : ''),
			stock: new FormControl(sitem ? sitem.stock : {}),
			part: new FormControl(sitem ? sitem.part : {}),
			furniture: new FormControl(sitem ? sitem.furniture : {}),
			
		});
	}



    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();

		let itemsFormArray = new FormArray([]);
		if (input.items) {
			for (let items of input.items) {
				itemsFormArray.push(this.getItemsFormGroup(items));
			}
		}

        return fb.group({
            quote_no: [input.quote_no,Validators.required],
			customer: [input.customer ? input.customer.id : null],
            sub_total: [input.sub_total],
			total_price: [input.total_price],
      		vat_total: [input.vat_total],
			status: [input.status],
			approved_at: [input.approved_at],
			sent_at: [input.sent_at],
			admin_percentage: [input.admin_percentage],
			profit_percentage: [input.profit_percentage],
			items: itemsFormArray,
        })
    }

    static getListRow(row: QuoteModel) {
        return {
            id: row.id,
            quote_no:row.quote_no,
            created_at_formatted: moment(row.created_at).format('DD/MM/YYYY'),
            customer_reference_no: row.customer ? row.customer['reference_no'] + ' - ' + row.customer['first_name'] + ' ' + row.customer['last_name'] : '',
			status: row.status,
        };
    }

}