import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdvertModel } from "./advert.model";
import { PilgrimGuideModel } from "./pilgrim-guide.model";
import { POITypeModel } from "./poi-type.model";
import { UserModel } from "./user.model";

export class PointOfInterestModel {
    id:number;
    latitude:number;
    longitude:number;
    elevation:number;
	path: string;
    image:string;
    title:string;
    description:string;
    type:POITypeModel;
    advert:AdvertModel;
    pilgrim_guide:PilgrimGuideModel;

    constructor(input?){
        input = input || {};
        this.latitude = input.latitude || '';
        this.longitude = input.longitude || '';
        this.elevation = input.elevation || '';
		this.path = input.path || '';
        this.image = input.image || '';
        this.title = input.title || '';
        this.description = input.description || '';
        this.type = input.type || {};
        this.id = input.id || '';
        this.advert = input.advert || {};
        this.pilgrim_guide = input.pilgrim_guide || {};
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],//
            description: [input.description,Validators.required],
            type: [input.type,Validators.required],
            advert: [input.advert],
			path: [input.path],
            image: [input.image],
            latitude:[input.latitude],
            longitude:[input.longitude],
            elevation:[input.elevation],
            pilgrim_guide:[input.pilgrim_guide]
        })
    }


}

export class TrailPointModel {
    id:number;
    latitude:number;
    longitude:number;
    elevation:number;
    order_number:number;

    constructor(input?){
        input = input || {};
        this.latitude = input.latitude || '';
        this.longitude = input.longitude || '';
        this.elevation = input.elevation || '';
        this.id = input.id || '';
        this.order_number = input.order_number || '';
    }

}

export class RouteModel {

    id: number;
    title: string;//
    best_time_of_year: string;//
    created_by: UserModel;
    description: string;//
    difficulty: string;//
    distance: number;//
    duration: number;//
    elevation_profile: string;//
    highest_elevation: number;//
    outer_image: string;//
    outer_image_path: string;//
    gallery: string[];
    lowest_elevation: number;//
    poi: PointOfInterestModel[];//
    suitability: string;
    surface: string;
    terrain: string;
    trail_points:any[];//
    sub_title:string;
	general_information:string;
	getting_there:string;
	what_to_pack_and_wear:string;
	other_information:string;

    //non api fields
    total_points:number;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.sub_title = input.sub_title || '';
        this.title = input.title || '';
        this.best_time_of_year = input.best_time_of_year || '';
        this.description = input.description || '';
        this.difficulty = input.difficulty || '';
        this.distance = input.distance || '';
        this.duration = input.duration || '';
        this.elevation_profile = input.elevation_profile || '';
        this.highest_elevation = input.highest_elevation || 0;
        this.outer_image = input.outer_image || '';
        this.outer_image_path = input.outer_image_path || '';
        this.gallery = input.gallery || '';
        this.lowest_elevation = input.lowest_elevation || 0;
        this.poi = input.poi || '';
        this.suitability = input.suitability || '';
        this.surface = input.surface || '';
        this.terrain = input.terrain || '';
        this.trail_points = input.trail_points || [];
        this.total_points = input.trail_points ? input.trail_points.length : 0;
		this.general_information = input.general_information || '';
		this.getting_there = input.getting_there || '';
		this.what_to_pack_and_wear = input.what_to_pack_and_wear || '';
		this.other_information = input.other_information || '';

    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title, Validators.required],//
            best_time_of_year: [input.best_time_of_year],
            description: [input.description, Validators.required],//
            difficulty: [input.difficulty, Validators.required],//
            distance: [input.distance, Validators.required],//
            duration: [input.duration, Validators.required],//
            elevation_profile: [input.elevation_profile],
            highest_elevation: [input.highest_elevation],
            gallery: [input.gallery],
            outer_image:[input.outer_image],
			outer_image_path:[input.outer_image_path],
            lowest_elevation: [input.lowest_elevation],
            poi: [input.poi],
            suitability: [input.suitability],
            surface: [input.surface],
            terrain: [input.terrain],
            trail_points:[input.trail_points],
            sub_title:[input.sub_title, Validators.required],
			general_information:[input.general_information],
			getting_there:[input.getting_there],
			what_to_pack_and_wear:[input.what_to_pack_and_wear],
			other_information:[input.other_information]
        })
    }

    static getListRow(row: RouteModel) {
        return {
            id: row.id,
            title: row.title
        };
    }

    getTotalPoints(){
        return this.total_points;
    }

}