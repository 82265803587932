import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as $ from 'jquery';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { pgSelectComponent } from '../../../@pages/components/select/select.component';
import { pgOptionComponent } from '../../../@pages/components/select/option.component';
import { isArray } from 'rxjs/internal-compatibility';
//import { PatientService } from '../../../shared/services/patient.service';

@Component({
  selector: 'paginated-select',
  templateUrl: './paginated-select.component.html',
  styleUrls: ['./paginated-select.component.scss']
})
export class PaginatedSelectComponent implements OnInit {

  @Input() paginated: boolean = true;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreateRequest: EventEmitter<any> = new EventEmitter<any>();
  @Input() incomingEvent: EventEmitter<any>;
  @Input() service: any;
  @Input() fieldName: string = "Default";
  @Input() label: string[];
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() permissions: string[] = [];
  @Input() showCreate: boolean = false;
  @Input() row: any;
  @Input() optionValue: string = 'id';
  @Input() fullObject: boolean = false;
  @Input() limit: number = 25;
  @Input() hideSelectedValues: boolean = true;
  @Input() selectedValues: number[] = [9874, 123123];
  @Input() onlyShowField: boolean = false;
  @Input() forceRow: any;
  @Input() filterBy: string;
  @Input() reloadOnClose: boolean = false;
  @Input() showClear: boolean = true;
  @ViewChild('selectProject', { static: false }) pg_select: pgSelectComponent;

  @Input() originForm: FormGroup;
  @Input() control: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  @ViewChild('selectProject', { static: false }) selectComponent: pgSelectComponent;

  ngOnInit() {
    let _this = this;

    if (!this.form) {
      this.createForm();
    }
    else {
      this.form.get(this.controlName).valueChanges.subscribe(res => {
      },
        err => {
          console.log(err);
        })
    }
    this.loadRows();
    if (this.row) {
      this.form.get('row').setValue(this.row);
    }
    this.handleIncomingEvent();
    this.handleForceRow();
    let interval = setInterval(() => {
      if (_this.selectComponent) {
        _this.selectComponent.ScrollToBottom.subscribe(res => {
        });
        _this.selectComponent.OpenChange.subscribe(res => {
          if (!res) {
            _this.term = "";
          }
        })
        clearInterval(interval);
      }
    }, 2000);
  }

  handleIncomingEvent() {
    let _this = this;
    if (this.incomingEvent) {
      this.incomingEvent.subscribe(res => {
        if (res == null) {
          // if (_this.service instanceof WorkService || _this.service instanceof ProjectService) {
          //   _this.form.get('row').setValue(-1);
          //   _this.row = -1;
          //   _this.cdr.detectChanges();
          //   console.log("Set to -1");
          // }
          return;
        }

        if (res['organisation']) {
          let cur = _this.form.get('row').value ? _this.form.get('row').value : [];
          cur.push(res['organisation']);
          _this.form.get('row').setValue(cur);
          return;
        }

        let handleClearItem = () => {
          if (res['type'] == 'clear') {
            _this.form.get('row').setValue(null);
            _this.row = null;
            _this.cdr.detectChanges();
          }
        }

        let handleItemAdd = () => {

          let checkIfAlreadyHas = () => {
            if (_this.multiple) {
              if (_this.row) {
                for (let v of _this.row) {
                  if (v == res[_this.optionValue]) {
                    return true;
                  }
                }
              }
            }
            else {
              if (_this.row == res[_this.optionValue]) {
                return true;
              }
            }
            return false;
          };

          let handleItemAddMultiple = () => {
            if (!_this.row) {
              _this.row = [];
            }
            if (!_this.row.includes(res[_this.optionValue])) {
              _this.row = _this.row ? [..._this.row, res[_this.optionValue]] : [res[_this.optionValue]];
            }
          }

          let handleFilterRows = () => {
            // let tmp: Map<number, any> = new Map<number, any>();
            // for (let rw of _this.rows) {
            //   tmp.set(rw['id'], rw);
            // }
            // _this.rows = Array.from(tmp.values());
            _this.updateRows();
          }

          let setForm = () => {
            _this.form.get('row').setValue(_this.row);
          }

          if (!checkIfAlreadyHas()) {
            if (_this.multiple) {
              handleItemAddMultiple();
            }
            else {
              _this.row = res[_this.optionValue];
            }
            setForm();
          }

          // let hasRow = (res) => {
          //   for (let rw of _this.rows) {
          //     if (rw[_this.optionValue] == res[_this.optionValue]) {
          //       return true;
          //     }
          //   }
          //   return false;
          // }

          // console.log(res);
          // if (!hasRow(res)) {
          //   //_this.rows.push(res);
          // }
          handleFilterRows();
        }



        if (res['type'] == 'clear') {
          handleClearItem();
        }

        else {
          handleItemAdd();
        }
      })
    }
  }

  handleForceRow() {
    if (this.forceRow) {
      let rawforceRow = this.forceRow.getRawValue();
      this.form.get('row').setValue(rawforceRow[this.optionValue], { emitEvent: false });
    }
  }

  resetForm() {
    // this.row = null;
    // this.form.get('row').reset(null,{ emitEvent: false });
    this.form.get('row').setValue(null, { emitEvent: false });
    this.row = null;
    this.cdr.detectChanges();
  }

  hasValue(row) {
    return this.selectedValues.includes(+row[this.optionValue]);
  }

  // getFilteredList(){
  //   let _this = this;
  //   return this.rows.filter(row => {
  //     return !_this.selectedValues.includes(+row[_this.optionValue]);
  //   });
  // }
  activeRows: any[] = [];
  @Input() form: FormGroup;
  @Input() controlName: string = "row";
  createForm() {
    let _this = this;

    this.form = this.fb.group({
      row: ['', (this.isControlRequired() ? Validators.required : Validators.nullValidator)],
    });

    this.form.get('row').valueChanges.subscribe(res => {
      let emitChange = () => {
        if (res && _this.multiple) {
          for (let resV of res) {
            if (_this.multiple && !_this.activeRows.includes(resV)) {
              _this.activeRows.push(resV);
            }
          }
        }
        _this.onChange(res);
      }

      // if (!_this.fullObject) {
      //   _this.service.get(res).subscribe(objRes => {
      //     if(objRes['data']['deleted_at']){
      //       console.error("Selected Deleted Value");
      //     }
      //     else{
      //       _this.onChange(res);
      //     }
      //   },
      //   err => {
      //     console.error(err);
      //   });
      // }
      // else{
      //   emitChange();
      // }
      emitChange();
    });
  }

  isTouched(controlName) {
    if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  fetchMore() {
    if (!this.paginated) {
      return;
    }
    this.loadRows();
  }

  onChange(event) {
    this.valueChange.emit(event);
  }

  sendCreateRequest() {
    this.onCreateRequest.emit(true);
  }

  loadRows(forceReset?) {
    if (forceReset) {
      this.rearchedCap = false;
      this.lastPage = 1;
      this.rows = [];
    }
    let _this = this;
    if (this.rearchedCap) {
      return true;
    }

    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }

    if (_this.subscription) {
      _this.subscription.unsubscribe();
    }

    _this.timeout = setTimeout(() => {

      _this.subscription = _this.service.getAll(_this.getParams()).subscribe(res => {

        if (_this.paginated) {
          if (!_this.rows) {
            _this.rows = [...res['data']];
          }
          else {
            _this.rows = [..._this.rows, ...res['data']];
          }

          // if (_this.service instanceof WorkService || _this.service instanceof ProjectService) {
          //   if(_this.rows && (_this.rows.length == 0 || _this.rows[0].id != null)){
          //     _this.rows = [{id:-1,title:"No "+(_this.service instanceof WorkService ? "Works" : "Project")},..._this.rows];
          //   }
          // }

          if (+_this.lastPage == +res['meta']['last_page']) {
            _this.rearchedCap = true;
          }
        }
        else {
          _this.rows = res['data'];
        }

        _this.updateRows();

        _this.lastPage++;
      },
        err => {
          console.log(err);
        })
    }, 350);
  }

  rows: any[] = [];
  timeout: any;
  lastPage = 1;
  rearchedCap: boolean = false;
  subscription: Subscription;
  term: string;

  onSearch(event) {
    if (this.disabled || !this.paginated) {
      return;
    }
    this.term = event;
    let _this = this;
    this.lastPage = 1;
    this.rearchedCap = false;
    this.rows = [];
    if (_this.term == null || _this.term == '') {
      this.loadRows();
      return;
    }
    if (_this.timeout) {
      clearTimeout(_this.timeout);
    }
    _this.timeout = setTimeout(() => {
      if (_this.subscription) {
        _this.subscription.unsubscribe();
      }
      this.service.getAll(_this.getParams()).subscribe(res => {
        _this.rows = res['data'];
        if (_this.multiple) {
          for (let v of _this.activeRows) {
            _this.service.get(v).subscribe(resValue => {
              let row = resValue['data'];
              let searchName = "";
              for (let i = 0; i < _this.label.length; i++) {
                let lbl = _this.label[i];
                searchName += (i == _this.rows.length ? row[lbl] : row[lbl] + (_this.label[i].includes("name") ? ' ' : (i == (_this.label.length - 1) ? '' : ' - ')));
              }
              row['search_name'] = searchName;
              _this.rows = [row, ..._this.rows];
              _this.updateRows();
            },
              err => {
                console.log("Failed loading paginated prefilled value on search");
              });
          }
        }
        else {
          _this.updateRows();
        }
      },
        err => {
          console.log(err);
        })

    }, 350);
  }

  unlistedItem = {
    id: -1,
    tax_code: null,
    deleted_at: null,
    reference: "Unlisted Item",
    title: "Unlisted Item",
    description: "Unlisted Item",
    //selling_price: 0,
    //cost_price: 0,
    vat_price: 0,
    stock_amount: 0,
    stock_notification_amount: 0,
    stock_location: "",
    image: "",
    supplier: null,
    recommended_order_amount: 0,
    search_name: "Unlisted Item"
  };

  detectState(event) {
    this.form.markAllAsTouched();
    if (event) {
      //this.onSearch("");
    }
  }

  @Input() params: any;
  getParams() {
    let _this = this;
    let params = { per_page: _this.limit, page: _this.lastPage };//{ q: _this.term, per_page: _this.limit, page: this.lastPage };
    if (_this.term) {
      params['q'] = this.term;
    }
    if (_this.params) {
      params = { ...params, ..._this.params };
    }
    return params;
  }

  updateRows() {
    let _this = this;
    for (let row of _this.rows) {
      if (_this.label.length == 1 && _this.label[0] == 'invoice_search_name') {
        //console.log(row);
        row['search_name'] = row['id'];
        if (row['jobsheet']) {
          let jobsheets = [];
          let lifts = [];
          for (let job of row['jobsheet']) {
            jobsheets.push(job['id']);
            if (job['lift']) {
              lifts.push((job['lift']['reference'] ? job['lift']['reference'] : job['lift']['id']));
            }
          }
          if (jobsheets.length > 0) {
            row['search_name'] = row['search_name'] + ' - Jobsheet:' + (jobsheets.join(","));
          }
          if (lifts.length > 0) {
            row['search_name'] = row['search_name'] + ' - Lift:' + (lifts.join(","));
          }
        }
      }
      else {
        let searchName = "";
        for (let i = 0; i < _this.label.length; i++) {
          let lbl = _this.label[i];
          searchName += (i == _this.rows.length ? row[lbl] : row[lbl] + (_this.label[i].includes("name") ? ' ' : (i == (_this.label.length - 1) ? '' : ' - ')));
        }
        row['search_name'] = searchName;
      }
    }

    let filtered: Map<number, any> = new Map<number, any>();
    _this.rows.map((val, key) => {
      filtered.set(val.id, val);
    });
    _this.rows = [...Array.from(filtered.values())];

    if (_this.filterBy) {
      _this.rows = _this.rows.filter(val => val[_this.filterBy['field_name']] == _this.filterBy['field_value']);
    }

    if (_this.rows) {
      if (_this.rows.length == 0) {
        //_this.utils.errorMessage("No Results Found.");
      }
    }

    //console.log("Updated Rows");
  }
  // let _this = this;
  // for (let row of _this.rows) {
  //   let searchName = "";
  //   for (let i = 0; i < _this.label.length; i++) {
  //     let lbl = _this.label[i];
  //     searchName += (i == _this.rows.length ? row[lbl] : row[lbl] + (_this.label[i].includes("name") ? ' ' : (i == (_this.label.length - 1) ? '' : ' - ')));
  //   }
  //   row['search_name'] = searchName;
  // }
  // return;
  // let filtered: Map<number, any> = new Map<number, any>();
  // _this.rows.map((val, key) => {
  //   filtered.set(val[_this.optionValue], val);
  // });
  // _this.rows = [...Array.from(filtered.values())];

  // console.log(_this.rows);
  // if (_this.filterBy) {
  //   _this.rows = _this.rows.filter(val => val[_this.filterBy['field_name']] == _this.filterBy['field_value']);
  //   console.log(_this.rows);
  // }

  // if (_this.rows) {
  //   if (_this.rows.length == 0) {
  //     //_this.utils.errorMessage("No Results Found.");
  //   }
  // }
  // //console.log("Updated Rows");

  isControlRequired = (): boolean => {
    try {
      const validator = this.originForm.get(this.control).validator({} as AbstractControl);
      //console.log(this.control + " = " + validator.required);
      if (validator && validator.required) {
        return true;
      }
    }
    catch (Err) {

    }
    return false;
  };

}