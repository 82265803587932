import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(searchParams) {
    return this.http.get(`${environment.baseUrl}/route`, { params: searchParams });
  }

  get(id) {
    return this.http.get(`${environment.baseUrl}/route/` + id);
  }
  
  getTrail(id) {
    return this.http.get(`${environment.baseUrl}/route/` + id + `/trail`);
  }

  edit(id, formData) {
    return this.http.put(`${environment.baseUrl}/route/` + id, formData);
  }

  editPOI(id, formData) {
    return this.http.put(`${environment.baseUrl}/route/` + id+'/poi', formData);
  }

  create(formData) {
    return this.http.post(`${environment.baseUrl}/route`, formData);
  }

  delete(id) {
    return this.http.delete(`${environment.baseUrl}/route/` + id);
  }

  generateRoute(from,to) {
    return this.http.post(`${environment.baseUrl}/route/generate`, {
      start: from, 
      end: to
    });
  }

}
