import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../shared/services/auth.service';
import {  MessageService } from '../../../../@pages/components/message/message.service';
import { Utils } from '../../../../shared/services/utils.service';
import { TableComponent } from '../../../components/codeblack-table/codeblack-table.component';
import { FurnitureCategoryService } from '../../../../shared/services/furniture-category.service';
import { FurnitureCategoryBuildData } from '../category.build-data';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class FurnitureCategoryListComponent implements AfterViewInit {

	freeSearch: string;
	searchParams:any = { per_page: environment.per_page };
  
	constructor(
	  private router: Router,
	  public service: FurnitureCategoryService,
	  private authService: AuthService,
	  private messageService: MessageService,
	  private utils: Utils,
	  //private route: ActivatedRoute
	) {
  
	}
  
	ngAfterViewInit() {
	  //this.loadFilters();
	  this.updateMenuItems();
	}
  
	data = new FurnitureCategoryBuildData();
  
	module = this.data.module; 
	onReload: EventEmitter<boolean> = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
	rowFunction = this.data.rowFunction;
	columns = this.data.columns;
	@ViewChild(TableComponent, { static: false }) table: TableComponent;
  
	loading = {
	  isFirstLoad: false,
	  isLoading: false
	};
  
	//Dot Menu Section
  
	submitToggle() {
	  this.utils.submitToggle(this.activeActionTitle, this.messageService, this.searchParams, Array.from(this.activeRows.values()), this.module.new_name);
	  this.activeRows = new Map<number, any>();
	}
  
	activeActionTitle: string;
	menuItems: any[] = [
	  { action: 'export-selected', message: 'Export Selected', class: '', icon: 'fal fa-file-export', permissions: ['view_group'] },
	  { action: 'export-all', message: 'Export All', class: '', icon: 'fal fa-file-export', permissions: ['view_group'] },
	];
  
	handleButtonPress = (event) => {
	  switch (event) {
		case "export-selected":
		  this.activeActionTitle = "Export Selected";
		  this.openTableBulk();
		  break;
		case "export-all":
		  this.activeActionTitle = "Export All";
		  this.openTableBulk();
		  break;
		default:
		  console.log(event);
		  break;
	  }
	}
  
	openTableBulk() {
	  if (this.table) {
		this.table.openBulk();
	  }
	  else {
		console.log("Failed to find codeblack table.");
	  }
	}
  
	activeRows: Map<number, any> = new Map<number, any>();//Required for codeblack table when using bulk selection.
	updateMenuItems() {
	  for (let item of this.menuItems) {
		if (this.activeRows.size == 0) {
		  if (item.action.includes('export-selected')) {
			item.Disabled = true;
		  }
		}
		else {
		  if (item.action.includes('export-selected')) {
			item.Disabled = false;
		  }
		}
	  }
	  return this.menuItems;
	}
  
	//Filter Section
  
	updateParams(event) {
	  this.searchParams = event;
	  this.saveFilters();
	}
  
	clearFilters() {
	  this.freeSearch = "";
	  if (this.table && this.table.table) {
		this.table.clearFilters();
	  }
	  this.updateFilters();
	}
  
	historyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	filteredParams: any = {};
	filterUpdateTimeout: any;
	setFilter(name, value) {
	  let _this = this;
	  _this.filteredParams = { ..._this.filteredParams, [name]: value };
	  this.utils.pushFiltersToRouter(value, name, _this.filteredParams).subscribe(res => {
		window.history.pushState(null, "", (this.module.new_name + res));
		_this.historyChange.emit(true);
	  });
	}
  
	// getFilter(name): any {
	//   let value = this.route.snapshot.queryParams[name];
	//   if (isNaN(+value)) {
	// 	return value;
	//   }
	//   return +value;
	// }
  
	saveFilters() {
	  let _this = this;
	  setTimeout(() => {
		if (_this.table) {
		  _this.setFilter("page", _this.table.currentPage);
		  if (_this.table.order_by) {
			_this.setFilter("order_by", _this.table.order_by);
			_this.setFilter("order_asc_desc", _this.table.order_asc_desc);
		  }
		  else{
			_this.setFilter("order_by", null);
			_this.setFilter("order_asc_desc", null);
		  }
		}
		_this.setFilter("search", _this.freeSearch);
	  }, 250);
	}
  
	// loadFilters() {
	//   this.freeSearch = this.getFilter("search");
	//   if (this.table && this.getFilter("page")) {
	// 	let page = (this.getFilter("page") ? this.getFilter("page") : 1);
	// 	this.table.currentPage = page;
	//   }
	//   if (this.getFilter("order_by") && this.table) {
	// 	this.table.order_by = this.getFilter("order_by");
	// 	this.table.order_asc_desc = this.getFilter("order_asc_desc");
	//   }
	//   this.updateFilters();
	// }
  
	filterLoadTimeout: any
	updateFilters(skip?) {
	  let _this = this;
	  _this.searchParams = { per_page: environment.per_page, page: 1 };
	  if(skip){
		this.table.currentPage = 1;
	  }
	  if (this.freeSearch) {
		this.searchParams['q'] = this.freeSearch;
	  }
  
	  if (this.table) {
		if (this.table.order_by) {
		  this.searchParams['order_by'] = this.table.order_by;
		  this.searchParams['order_asc_desc'] = this.table.order_asc_desc;
		}
		if (this.table.currentPage){
		  this.searchParams['page'] = this.table.currentPage;
		}
	  }
  
	  if (this.filterLoadTimeout) {
		clearTimeout(this.filterLoadTimeout);
	  }
	  this.filterLoadTimeout = setTimeout(() => {
		this.onReload.emit(true);
	  }, 350);
	  this.saveFilters();
	}
  

}
