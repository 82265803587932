import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../shared/models/column.model";
import { PartModel } from "../../shared/models/part.model";
export class PartBuildData{
    module = {
        name: "Part",
        new_name: "part",
        filtered_name:"Part",
        has_dot_menu: true,
        icon:'fa-browser',
        permission:'part'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:PartModel) => {//Generates Row for codeblack-table
        return PartModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Title",
            prop: "title",
            flex_grow: 4,
            row_accessor: "title",
        },
		{
            title: "Reference",
            prop: "reference",
            flex_grow: 4,
            row_accessor: "reference",
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}