import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { LocalStoreService } from './local-store.service';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { UserModel } from '../models/user.model';
import { WebsocketService } from './websocket.service';
import { MenuNavService } from './menu-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  triggered:boolean = false;
  initialTimeout:any;
  authenticated = false;
  token : any;
  isForgotPassword = false;
  user:UserModel;
  didFirstCheck:boolean = false;
  private companyLogo:string ="/assets/img/web-logo.svg";

  constructor(
    private http: HttpClient,
     private store: LocalStoreService,
     private userService : UserService,
     private router : Router,
     private wsService : WebsocketService) {
    this.checkAuth(null);
  }

  timeout;

  // authenticate(){
  //   let _this = this;
  //   if(_this.timeout){
  //     clearTimeout(_this.timeout);
  //   }
  //   _this.timeout = setTimeout(() => {
  //     _this.userService.get(_this.getUserId()).subscribe(res => {
  //       _this.user = res['data'];
  //     },
  //     err => {
  //       _this.signout();
  //     });
  //   }, 350);
  // }

  getFavicon(){
    return "/assets/img/dashboardlogo.svg";  
  }

  permissions:any[];

  hasPermission(permission_name: string) {
    if(!this.permissions){
      if(this.store.getItem('currentUser')){
        this.permissions = this.store.getItem('currentUser')['permissions'];
      }
    }
    if(this.permissions){
      if(this.permissions.includes(permission_name)){
        return true;
      }
    }
    else{
      this.signout();
    }
    return false;
  }

  getLogo(withTagline?){
    // if(withTagline){
    //   return "/assets/img/logo_with_tagline.svg";
    // }
    return "/assets/icons/logo.svg";
  }

  getBackground(){
    return {'background':'url(/assets/img/stanthony-background.jpg)'};
  }

  checkAuth(cdr) {
    //this.authenticated = !!this.store.getItem('currentUser');
    if(!this.store.getItem('currentUser')){
      this.authenticated = false;
      return;
    }
    else{
      let _this = this;
      //perform Auth Check here.
      this.token = this.store.getItem('currentUser')['token'];
      this.userService.get(this.store.getItem('currentUser')['id']).subscribe(res => {
        _this.didFirstCheck = true;
        _this.authenticated = true;
        _this.user = this.store.getItem('currentUser');
        //_this.wsService.listenChannel("private-user-channel-",this.getUserId(),cdr);
      },
      err => {
        _this.didFirstCheck = true;
        this.signout();
      })
    }
  }

  getUser() {
    return of(this.store.getItem('currentUser'));
  }

  getUserId(){
    const user : any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return user.id;
  }

  getSystemRoles(){
    return this.http.get(`${environment.baseUrl}/systemroles`);
  }

  getUserRole() {
    const user: any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return 1;
    //return user.system_role[0].id;
  }

  forgotToken(username){
    return this.http.post(`${environment.baseUrl}/auth/forgot/password`,{email:username});
  }

  forgotUpdate(formData){
    return this.http.post(`${environment.baseUrl}/auth/password/reset`,formData);
  }

  updateCredentials(formData){
    return this.http.put(`${environment.baseUrl}/user/profile`,formData);
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.baseUrl}/auth/login`, { username: credentials.username, password: credentials.password });
  }

  getRolePermissions(roleId){
    let permission_types:string[] = [];
    let permission_names:string[] = [];
    let permissions:string[] = [];

    //console.log(roleId);

    if(roleId == 1){//Admin
      permission_types = ["create","update","delete","view"];
      permission_names = ["report","timesheet","user","client","dashboard","project","employee"];
      //permissions.push("view_settings");
    }

    // console.log(permission_types);
    // console.log(permission_names);

    for(let type of permission_types){
      for(let perm_name of permission_names){
        permissions.push(type+"_"+perm_name);
      }
    }
    
    return permissions;
  }

  isMaintenance(): boolean {
    return this.getUserRole() == 5;
  }

  isInstaller(): boolean {
    return this.getUserRole() == 4;
  }

  isTechnician(): boolean {
    return this.getUserRole() == 3;
  }

  isAccounts(): boolean {
    return this.getUserRole() == 2;
  }

  isAdmin(): boolean {
    return this.getUserRole() == 1;
  }

  signout() {
    let _this = this;
    this.authenticated = false;
    this.didFirstCheck = false;
    _this.store.setItem('currentUser', null);
    _this.router.navigate(['/session/login']);
  }


}
