import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FurnitureCategoryModel } from "./furniture-category.model";
import { UploadModel } from "./upload.model";

export class FurnitureModel {

	id: number;
	title: string;
	category: FurnitureCategoryModel[];
	profit_percentage: string;
	admin_percentage: string;
	labour_minute: string;
	image: UploadModel[];
	description: string;
	reference: string;
	total_cost: string;
	total_selling: string;
	parts: any[];
	stocks: any[];


	constructor(input?) {
		input = input || {};
		this.id = input.id || '';
		this.title = input.title || '';
		this.description = input.description || '';
		this.category = input.category || [];
		this.profit_percentage = input.profit_percentage || '';
		this.admin_percentage = input.admin_percentage || '';
		this.labour_minute = input.labour_minute || '';
		this.image = input.image || [];
		this.reference = input.reference || '';
		this.total_cost = input.total_cost || '';
		this.total_selling = input.total_selling || '';
		this.parts = input.parts || [];
		this.stocks = input.stocks || [];
	}

	static getStockFormGroup(stock?) {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			quantity: new FormControl(stock ? stock.quantity : 1),
			id: new FormControl(stock ? stock.id : ''),
			length: new FormControl(stock ? stock.length : ''),
			width: new FormControl(stock ? stock.width : ''),
			height: new FormControl(stock ? stock.height : ''),
			stock: new FormControl(stock ? stock.stock : {})
		});
	}

	static getPartFormGroup(part?) {
		let fb: FormBuilder = new FormBuilder();
		
		let itemsFormArray = new FormArray([]);
		
		if ( part.items) {
			for (let item of part.items) {
				itemsFormArray.push(this.getItemFormGroup(item));
				//itemsFormArray.push(this.getItemFormGroup(item));
				//partsFormArray.push(this.items());
			}
		}

		return fb.group({
			quantity: new FormControl(part ? part.quantity : ''),
			...part,
			items: itemsFormArray
		});	
	}

	static getItemFormGroup(item?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			quantity: new FormControl(item ? item.quantity : 1),
			title: new FormControl(item ? item.title : ''),
			length: new FormControl(item ? item.length : ''),
			width: new FormControl(item ? item.width : ''),
			height: new FormControl(item ? item.height : ''),
			id: new FormControl(item ? item.id : ''),
			item: new FormControl(item ? item.stock : ''),
			
		});	
	}

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();

		let partsFormArray = new FormArray([]);
		
		if (input.parts) {
			for (let part of input.parts) {
				partsFormArray.push(this.getPartFormGroup(part));
			}
		}

		let stocksFormArray = new FormArray([]);
		if (input.stocks) {
			for (let stock of input.stocks) {
				stocksFormArray.push(this.getStockFormGroup(stock));
			}
		}

		return fb.group({
			title: [input.title, Validators.required],
			description: [input.description],
			category: [input.category ? input.category.id : null, Validators.required],
			profit_percentage: [input.profit_percentage],
			admin_percentage: [input.admin_percentage],
			labour_minute: [input.labour_minute],
			image: [input.image ? input.image.id : null],
			reference: [input.reference],
			total_cost: [input.total_cost],
			total_selling: [input.total_selling],
			parts: partsFormArray,
			stocks: stocksFormArray,
			
		})
	}

	static getListRow(row: FurnitureModel) {
		return {
			id: row.id,
			title: row.title,
		};
	}

}