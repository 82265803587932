import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UnitModel } from "./unit.model";
import { UploadModel } from "./upload.model";

export class StockModel {

    id: number;
    title:string;
    description:string;
	cost_price:string;
	image:UploadModel[];
    cost_price_unit: UnitModel[];
	selling_price_unit:UnitModel[];;
	reference: string;
	selling_price:string;
	type: UnitModel[];


    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.description = input.description || '';
		this.cost_price = input.cost_price || '';
		this.image = input.image || [];
		this.cost_price_unit = input.cost_price_unit || [];
		this.reference = input.reference || '';
		this.selling_price = input.selling_price || '';
		this.selling_price_unit = input.selling_price_unit || '';
		this.type = input.type || [];
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            description: [input.description],
            used_for: [input.used_for],
			cost_price: [input.cost_price,Validators.required],
			image: [input.image ? input.image.id : null,],
			cost_price_unit: [input.cost_price_unit ? input.cost_price_unit.id : null,Validators.required],
			reference: [input.reference,Validators.required],
			selling_price: [input.selling_price,Validators.required],
			type: [input.type ? input.type.id : null,Validators.required],
			selling_price_unit: [input.selling_price_unit ? input.selling_price_unit.id : null],
        })
    }

    static getListRow(row: StockModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}