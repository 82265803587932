import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../shared/services/utils.service';
import { CertificateBuildData } from '../certificate.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { CertificateModel } from '../../../shared/models/certificate.model';
import { CertificateService } from '../../../shared/services/certificate.service';

@Component({
  selector: 'app-certificate-detail',
  templateUrl: './certificate-detail.component.html',
  styleUrls: ['./certificate-detail.component.scss']
})
export class CertificateDetailComponent implements OnInit {

  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  object: CertificateModel;
  roles: any[];
  @ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
  errors: String[] = [];
  data = new CertificateBuildData();
  module = this.data.module;
  form: FormGroup;
  editorInitializationData: any = {
    height: 600,
    block_unsupported_drop: false,
    menubar: true,
    plugins: [
      'advlist autolink lists link image'
    ],
    toolbar:
      'undo redo | styleselect | bold italic | \ alignleft aligncenter alignright alignjustify forecolor backcolor | fontsizeselect | \ bullist link image outdent indent'
  };

  constructor(
    private service: CertificateService,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    public permissionService: PermissionService
  ) { }

  @ViewChild('editor', { static: false }) editor: EditorComponent;
  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadData(res['params']['id']);
      }
    })
    setTimeout(() => {
      _this.onEditorDrop();
    }, 500);
  }

  onEditorDrop(){
    let _this = this;
    if(_this.editor){
      _this.editor.onDrop.subscribe(async (e: { editor, event }) => {
        let event = e.event;
  
        // Preventing 'Dropped file type is not supported' notification pop up
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        _this.utils.errorMessage("Dropping files is currently disabled.");
        // //let files: File[] = [...event.dataTransfer.files];
        // //let uploadedFiles = await this.uploadFiles(files); // backend call
        // console.log(e);
        // // uploadedFiles.forEach(file => {
        // //     // change for different file types
        // //     this.insertContent(`<img src="${file.Url}" />`);
        // // })
      })
    }
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
  ];

  handleButtonPress = (event) => {
    switch (event) {
      case "delete":
        this.modalDelete.show();
        break;
      default:
        console.log(event);
        break;
    }
  }

  incomingEvent: EventEmitter<any> = new EventEmitter<any>();
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    _this.form.get(fieldName).setValue(value);
  }

  markTouched() {
    this.form.markAsTouched();
  }

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  fileChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }

  @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  fromDate: Date = new Date();
  toDate: Date = moment(new Date()).add(1, 'hour').toDate();
  forceRefresh(dateEvent) {
    let _this = this;
    Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
      _this.cdr.detectChanges();
    })
  }

  _disabledStartDate = (startValue) => {
    return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
  };

  _disabledEndDate = (endValue) => {
    return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
  };

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = CertificateModel.getFormGroup(input);
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  isTouched(controlName) {
    return Utils.isFormTouched(this.form, controlName);
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadData(id) {
    let _this = this;
    let checkLoadingSpinner = () => {
      if (!_this.loading.isFirstLoad) {
        _this.loading.isFirstLoad = true;
        _this.loading.isLoading = true;
      }
    }

    checkLoadingSpinner();

    this.service.get(id).subscribe(res => {
      _this.object = res['data'];
      _this.createForm(_this.object);
      _this.triggerFetch.emit(_this.form);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  //Variable & Function for <phone-input>
  triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  updateForm(event) {
    this.form = event;
  }

  delete() {
    let _this = this;
    _this.isLoading = true;
    this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
      _this.isLoading = false;
    });
  }

  isFormValueChecked(controlName) {
    return this.form.get(controlName).value;
  }

  photoChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
        _this.form.get('image').setValue(res.cardImageBase64);
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }

  @Input() isLoading: boolean = false;


  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
  save() {
    let _this = this;
    _this.isLoading = true;

    // if(this.fromDate){
    //   _this.form.get("valid_from").setValue(moment(this.fromDate).format());
    // }

    // if(this.toDate){
    //   _this.form.get("valid_to").setValue(moment(this.fromDate).format());
    // }

    Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
    Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
      _this.isLoading = false;
      if (res['data']) {
        if (_this.external == true) {
          _this.saveEvent.emit(res['data']);
        }
        else {
          this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
        }
        _this.utils.successMessage(_this.module.name + ' saved.');
      }
      else {
        Utils.sendFormSaveError(res,this.module,this.utils);
      }
    })
  }


}
