import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../shared/services/utils.service';
import { PartBuildData } from '../part.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { title } from 'process';
import { PartModel } from '../../../shared/models/part.model';
import { PartService } from '../../../shared/services/part.service';
import { PartCategoryService } from '../../../shared/services/part-category.service';
import { PartCategoryModel } from '../../../shared/models/part-category.model';
import { UploadModel } from '../../../shared/models/upload.model';
import { UploadService } from '../../../shared/services/upload.service';
import { StockModel } from '../../../shared/models/stock.model';
import { StockService } from '../../../shared/services/stock.service';
import { SettingsService } from '../../../shared/services/settings.service';
import { WorkTimeService } from '../../../shared/services/worktime.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-part-detail',
	templateUrl: './part-detail.component.html',
	styleUrls: ['./part-detail.component.scss']
})
export class PartDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	object: PartModel;
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('addItemModal', { static: true }) modalAddItem: ModalDirective;
	@ViewChild('deleteItemModal', { static: true }) modalDeleteItem: ModalDirective;
	@ViewChild('changeItemModal', { static: true }) changeItemModal: ModalDirective;
	errors: String[] = [];
	data = new PartBuildData();
	module = this.data.module;
	form: FormGroup;
	_cat: PartCategoryModel[] = [];
	_img: UploadModel[] = [];
	_stock: StockModel[] = [];
	selectedStockValue: string = '';
	used_for = '';

	constructor(
		private service: PartService,
		private router: Router,
		private partCategoryService: PartCategoryService,
		private uploadService: UploadService,
		private stockService: StockService,
		private snapshot: ActivatedRoute,
		private settingsService: SettingsService,
		private worktimeService: WorkTimeService,
		private authService: AuthService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		public permissionService: PermissionService,
		private location: Location
	) { }

	ngOnInit() {
		if (this.location.path().indexOf('/furniture/part') > -1) {
			this.used_for = 'furniture';
		}
		else {
			this.used_for = 'doors';
			this.module.filtered_name = 'Door';
		}


		let _this = this;
		this.loadCat();
		this.loadImg();
		this.loadStock();
		this.loadEdgingStock();
		this.loadSprayStock();
		this.loadWorkTime();
		this.createForm({});
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}
		})

		this.settingsService.get(5).subscribe(res => {
			_this.form.get('profit_percentage').setValue(res['data']['value']);
			_this.triggerFetch.emit(_this.form);
		})

		this.settingsService.get(6).subscribe(res => {
			_this.form.get('admin_percentage').setValue(res['data']['value']);
			_this.triggerFetch.emit(_this.form);
		})

	}

	loadCat() {
		var _this = this;
		this.partCategoryService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._cat.push(new PartCategoryModel(uObject));
			}
		});
	}

	loadStock() {
		var _this = this;
		this.stockService.getAll({ used_for: this.used_for, per_page: 99999 }).subscribe(res => {
			for (var uObject of res['data']) {
				_this._stock.push(new StockModel(uObject));
			}
		});
	}

	_edgingStock = [];

	loadEdgingStock() {
		var _this = this;
		this.stockService.getAll({ used_for: this.used_for, type: 6, per_page: 99999 }).subscribe(res => {
			for (var uObject of res['data']) {
				_this._edgingStock.push(new StockModel(uObject));
			}
		});
	}

	_sprayStock = [];

	loadSprayStock() {
		var _this = this;
		this.stockService.getAll({ used_for: this.used_for, type: 3, per_page: 99999 }).subscribe(res => {
			for (var uObject of res['data']) {
				_this._sprayStock.push(new StockModel(uObject));
			}
		});
	}

	_workTime = [];
	loadWorkTime() {
		var _this = this;
		this.worktimeService.getAll({ per_page: 99999 }).subscribe(res => {
			for (var uObject of res['data']) {
				_this._workTime.push(uObject);
			}
		});
	}

	loadImg() {
		var _this = this;
		this.uploadService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._img.push(new UploadModel(uObject));
			}
		});
	}

	menuItems: any[] = [
		{ action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
	];

	handleButtonPress = (event) => {
		switch (event) {
			case "delete":
				this.modalDelete.show();
				break;
			default:
				console.log(event);
				break;
		}
	}

	incomingGroupEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPaginatedSelectValue(fieldName, value) {
		let _this = this;
		switch (fieldName) {
			case 'groups':
				_this.form.get('groups').setValue(value);
				break;
		}
	}

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;

	fileChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
	@ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
	fromDate: Date = new Date();
	toDate: Date = moment(new Date()).add(1, 'hour').toDate();
	forceRefresh(dateEvent) {
		let _this = this;
		Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
			_this.cdr.detectChanges();
		})
	}

	_disabledStartDate = (startValue) => {
		return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
	};

	_disabledEndDate = (endValue) => {
		return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
	};

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	items: FormArray;
	labour_minute_breakdown: FormArray;
	createForm(input, form?) {
		input = input || {};
		input.used_for = this.used_for;
		form ? form : this.form = PartModel.getFormGroup(input);
		if (this.form) {
			this.items = this.form.get('items') as FormArray;
			this.labour_minute_breakdown = this.form.get('labour_minute_breakdown') as FormArray;
		}

	}
	targetItem
	selectedChangeStockID
	changePartStock(item, id)
	{
		this._stockDDList = this._stock.filter(itm => {
			return id == itm.type['id'];
		});
		this.targetItem = item;
		this.changeItemModal.show();
	}

	selectChangePartStock()
	{
		let stock = this.getStockByID(this.selectedChangeStockID);
		this.targetItem.get('stock').setValue(this.selectedChangeStockID);
		this.targetItem.get('_stock').setValue(stock);
		this.changeItemModal.hide();
	}

	activeItemID = null;
	_stockDDList = [];
	showActiveItemList(id)
	{
		this._stockDDList = this._stock.filter(itm => {
			return id == itm.type['id'];
		});
		this.modalAddItem.show();
	}

	statusclone = false
	selectedStock = [];
	selectedStockID = null;
	AddNewItem() {
		var _this = this;
		if (this.statusclone == false) {

			let stock = this.getStockByID(this.selectedStockID);

			this.items.push(PartModel.getItemFormGroup({
				stock: this.selectedStockID,
				quantity: 1,
				length: stock.type['id'] == 6 ? 2 : '',
				width: stock.type['id'] == 6 ? 2 : '',
				manual_measurement: false,
				use_part_volume: false,
				// width : '',
				height: '',
				_stock: stock
			}));
		} else if (this.statusclone == true) {

			//this.items.push(this.clonestockitem)
			// console.log(this.clonestockitem)
			// this.items.push(PartModel.getItemFormGroup({
			// 	stock: this.selectedStockID,
			// 	_stock: this.getStockByID(this.selectedStockID),
			// 	quantity: this.clonestockitem['quantity'],
			// 	spray: this.sprayarr['id']

			// }))
			this.items.push(PartModel.getItemFormGroup({
				stock: this.selectedStockID,
				quantity: this.clonestockitem['quantity'],
				length: this.clonestockitem.length,
				width: this.clonestockitem.width,
				manual_measurement: this.clonestockitem.manual_measurement,
				use_part_volume: this.clonestockitem.use_part_volume,
				height: this.clonestockitem.height,
				line_total: this.clonestockitem.line_total,
				total_measurement: this.clonestockitem.total_measurement,
				spray: this.sprayarr,
				edging: this.edgearr,
				worktime: this.clonestockitem.worktime,
				_stock: this.getStockByID(this.selectedStockID)
			}))
		}
		
		this.statusclone = false;
	}

	AddNewLabourMinute() {
		var _this = this;
		this.labour_minute_breakdown.push(PartModel.getLabourMinFormGroup({}));
	}

	getStockByID(id) {
		for (var stock of this._stock) {
			if (stock.id == id) {
				return stock;
			}
		}
	}

	selectStockChange(event: any) {
		this.selectedStockValue = event.target.value;
	}

	deleteLabourItem(idx) {
		this.labour_minute_breakdown.removeAt(idx);
	}

	deleteItem() {
		this.items.removeAt(this.deleteIdx);
		this.deleteIdx = null;
		this.modalDeleteItem.hide();
	}

	deleteIdx = null;

	deleteStock(idx) {
		this.deleteIdx = idx;
		this.modalDeleteItem.show();
	}

	deleteStockWorktime(idx, wtIdx) {
		(this.items.at(idx).get('worktime') as FormArray).removeAt(wtIdx);

	}

	deleteStockSpray(idx, wtIdx) {
		(this.items.at(idx).get('spray') as FormArray).removeAt(wtIdx);

	}

	deleteStockEdging(idx, wtIdx) {
		(this.items.at(idx).get('edging') as FormArray).removeAt(wtIdx);

	}

	addWorkTime(idx) {
		(this.items.at(idx).get('worktime') as FormArray).push(PartModel.getWorkTimeFormGroup({}));
	}

	addEdging(idx) {
		(this.items.at(idx).get('edging') as FormArray).push(PartModel.getEdgingFormGroup({}));
	}

	addSpray(idx) {
		(this.items.at(idx).get('spray') as FormArray).push(PartModel.getSprayFormGroup({}));
	}

	openFileUpload(ele) {
		$(ele).click();
	}

	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	isTouched(controlName) {

		if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
			return true;
		}

		else {
			return false;
		}
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
		}

		checkLoadingSpinner();

		this.service.get(id).subscribe(res => {
			_this.object = res['data'];
			_this.createForm(_this.object);
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err);
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isLoading = false;
		});
		this.router.navigate(['/furniture/part']);

	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}

	photoChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
				// _this.form.get('image').setValue(res.cardImageBase64);

				// _this.uploadService.create({data: res.cardImageBase64, file_type: res.fileType, file_ext : res.ext }).subscribe(uploadRes => {
				_this.uploadService.create({
					data: res.cardImageBase64,
					file_type: res.ext,
					file_ext: res.ext,
					description: res.fileName
				}).subscribe(uploadRes => {
					_this.form.get('image').setValue(uploadRes['data']['file_path']);
				}, err => {
					console.log(err);
				})

			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@Input() isLoading: boolean = false;


	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	save() {
		let _this = this;
		_this.isLoading = true;

		Utils.forceTouchPaginatedSelects(this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.object, this.module.name, () => { }, null).subscribe(res => {
			_this.isLoading = false;

			if (res['data']) {
				_this.createForm(res['data']);
				_this.triggerFetch.emit(_this.form);
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					if (_this.used_for == 'doors') {
						_this.router.navigate(['/door/part/' + res['data']['id']]);
					}
					else {
						_this.router.navigate(['/furniture/part/' + res['data']['id']]);

					}
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}

	clone() {
		let _this = this;
		_this.isLoading = true;

		let rawValue = this.form.getRawValue();

		rawValue['title'] += ' - CLONED';

		for (var i = 0; i < rawValue['items'].length; i++) {
			rawValue['items'][i]['id'] = null;

			for(var s = 0; s < rawValue['items'][i]['spray'].length; s++)
			{
				rawValue['items'][i]['spray'][s]['id'] = null;
			}

			for(var s = 0; s < rawValue['items'][i]['edging'].length; s++)
			{
				rawValue['items'][i]['edging'][s]['id'] = null;
			}

			for(var s = 0; s < rawValue['items'][i]['worktime'].length; s++)
			{
				// rawValue['items'][i]['worktime'][s]['id'] = null;
			}
		}

		this.service.create(rawValue).subscribe(res => {
			_this.isLoading = false;
			if (res['data']) {
				_this.createForm(res['data']);
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					if (_this.used_for == 'doors') {
						this.router.navigate(['/door/part/' + res['data']['id']]);
					}
					else {
						this.router.navigate(['/furniture/part/' + res['data']['id']]);

					}
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		});

	}

	clonestockitem
	sprayarr = []
	edgearr = []
	cloneStock(idx) {

		let stockrawdata = this.form.getRawValue();

		this.sprayarr = [];
		this.edgearr = [];
		
		this.statusclone = true;
		this.clonestockitem = stockrawdata['items'][idx]
		this.selectedStockID = this.clonestockitem['stock']
		// for(let wt of this.clonestockitem['worktime']){
		// 	wt['id'] = null;

		// }
		for(let wt of this.clonestockitem['spray']){

			wt['spray'] == wt['_object']['id']
			this.sprayarr.push({
				id:'',
				quantity: wt['quantity'],
				cost_price: wt['_object']['spray']['cost_price'],
				cost_price_unit: wt['_object']['spray']['cost_price_unit'],
				description: wt['_object']['spray']['description'],
				image: wt['_object']['spray']['image'],
				reference: wt['_object']['spray']['reference'],
				selling_price: wt['_object']['spray']['selling_price'],
				selling_price_unit: wt['_object']['spray']['selling_price_unit'],
				title: wt['_object']['spray']['title'],
				type: wt['_object']['spray']['type'],
				spray: wt['_object']['spray'],
				work_time: {id :wt['work_time']},
			})
		}
		for(let wt of this.clonestockitem['edging']){
			this.edgearr.push({
				id:'',
				manual_measurement: wt['_object']['manual_measurement'],
				title: wt['_object']['title'],
				length: wt['_object']['length'],
				width: wt['_object']['width'],
				total_measurement: wt['_object']['total_measurement'],
				height: wt['_object']['height'],
				edging: wt['_object']['edging'],
				work_time: {id :wt['work_time']},
			})
		}
		this.AddNewItem();
		this.statusclone = false;
		
		
	}


}
