import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../services/utils.service";

export class CustomerModel {

  id: number;
  address_1: string;
  address_2: string;
  email: string;
  first_name: string;
  last_name: string;
  post_code: string;
  photo:string;
  date_of_birth:string;
  company_name: string;
  reference_no: string;
  reference_no_with_name: string;
  vat_no: string;
  phone_number: string;
  phone_mobile: string;
  phone_other: string;
  contact_person: string;
  contact_person_email: string;
  invoice_email: string;

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
    this.first_name = input.first_name || '';
    this.last_name = input.last_name || '';
    this.email = input.email || '';
    this.post_code = input.post_code || '';
    this.phone_mobile = input.phone_mobile || '';
    this.address_2 = input.address_2 || '';
    this.address_1 = input.address_1 || '';
    this.vat_no = input.vat_no || [];
    this.photo = input.photo || '';
    this.date_of_birth = input.date_of_birth || '';
	this.company_name = input.company_name || '';
	this.reference_no = input.reference_no || '';
	this.reference_no_with_name = input.reference_no_with_name + ' - ' + input.first_name  + ' ' + input.last_name || '';
	this.phone_number = input.phone_number || '';
	this.phone_other = input.phone_other || '';
	this.contact_person = input.contact_person || '';
	this.contact_person_email = input.contact_person_email || '';
	this.invoice_email = input.invoice_email || '';
  }

  static getFormGroup(input): FormGroup {
    let fb: FormBuilder = new FormBuilder();
    return fb.group({
      first_name: [input.first_name, Validators.required],
      last_name: [input.last_name, Validators.required],
      email: [{ value: input.email, disabled: input.email }, Validators.required],
      address_1: [input.address_1, Validators.required],
      address_2: [input.address_2],
      post_code: [input.post_code],
      phone_mobile: [input.phone_mobile],
      photo:[input.photo],
      date_of_birth:[input.date_of_birth],
	  vat_no:[input.vat_no],
	  company_name:[input.company_name],
	  reference_no:[input.reference_no],
	  phone_number:[input.phone_number],
	  phone_other:[input.phone_other],
	  contact_person:[input.contact_person],
	  contact_person_email:[input.contact_person_email],
	  invoice_email:[input.invoice_email],
    })
  }

  static getListRow(row:CustomerModel) {
    return {
      id: row.id,
      name: row.first_name + ' ' + row.last_name,
	  address: Utils.getFormattedAddress(row),
      email: row.email
    };
  }

}