import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../../shared/models/column.model";
import { FurnitureCategoryModel } from "../../../shared/models/furniture-category.model";

export class FurnitureCategoryBuildData{
    module = {
        name: "Category",
        new_name: "furniture/category",
        has_dot_menu: true,
        icon:'fa-ad',
        permission:'furniture_category'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:FurnitureCategoryModel) => {//Generates Row for codeblack-table
        return FurnitureCategoryModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            flex_grow: 1,
            row_accessor: "id",
            class:""//force-flex force-flex-end
        },
        {
            title: "Title",
            prop: "title",
            flex_grow: 4,
            row_accessor: "title",
        }
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}