import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import { NotificationModel } from '../../../shared/models/notification.model';
import * as moment from 'moment';

@Component({
	selector: 'simplywhite-layout',
	templateUrl: './simplywhite.component.html',
	styleUrls: ['./simplywhite.component.scss'],
	encapsulation: ViewEncapsulation.None
})
// export class SimplyWhiteLayout extends RootLayout implements OnInit, OnDestroy {

//   constructor(
//     public toggler: pagesToggleService,
//     public router: Router,
//     public permissionsService: NgxPermissionsService,
//     private store: LocalStoreService,
//     private notificationService: NotificationService,
//     private subService: SubscriptionService,
//     private utils: Utils,
//     private cdr: ChangeDetectorRef,
//     private wsService: WebsocketService,
//     private authService: AuthService
//) {
//     super(toggler, router);
//   }

export class SimplyWhiteLayout extends RootLayout implements OnInit {

	ngOnInit() {
		let _this = this;
		document.documentElement.style.setProperty('--primary-color', '#515d75');
		document.documentElement.style.setProperty('--secondary-color', '#1E475B');
		document.documentElement.style.setProperty('--tertiary-color', '#007be8');
		document.documentElement.style.setProperty('--sidebar-color', '#ffffff');
		// document.documentElement.style.setProperty('--primary-color', '#ff0000');
		// document.documentElement.style.setProperty('--secondary-color', '#00ff00');
		// document.documentElement.style.setProperty('--tertiary-color','#0000ff');
		this.filterMenu();
		if (!this.authService.didFirstCheck) {
			this.init();
			this.authService.didFirstCheck = true;
		}
		//this.wsService.listenChannel("private-user-channel-", this.authService.getUserId(), this.cdr);

		// this.notificationService.onUpdate.subscribe(res => {
		//   console.log(res);
		//   _this.cdr.detectChanges();
		// },
		//   err => {
		//     console.log(err);
		//   })
	}

	getAvatar() {
		let user = this.store.getItem("currentUser");
		if (user && user.photo) {
			return user.photo;
		}
		return "assets/img/avatar.png";
	}

	init() {
		let _this = this;
		// this.changeLayout('menu-pin');
		// this.autoHideMenuPin();
		//this.loadNotifications(false);
		let timeout = setInterval(() => {
			if (_this.authService.authenticated) {
				clearTimeout(timeout);
			}
			else {
				_this.authService.checkAuth(_this.cdr);
			}
		}, 1000);
		// setTimeout(() => {
		//   _this.authService.checkAuth();
		// }, 500);
		if (!this.authService.triggered) {
			this.authService.triggered = true;
			this.authService.initialTimeout = setTimeout(() => {
				_this.authService.initialTimeout = null;
			}, 2000);
		}
	}

	handleSocket(res) {
		console.log(res);
	}

	getNotificationClasses(notification: NotificationModel) {
		let classes: string[] = [];
		classes.push(notification.notification_type);
		return classes;
		//{'unread':!notification.read_at,notification.type};
	}

	getHumanTime(date) {
		//console.log(asset.gps.timestamp);
		return moment.duration(moment(new Date()).diff(date)).humanize() + " ago";
	}

	filterMenu() {
		// if (!this.authService.permissions) {
		//   if (this.store.getItem('currentUser')) {
		//     this.authService.permissions = this.store.getItem('currentUser')['permissions'];
		//   }
		// }
		// if (this.authService.permissions) {
		//   this.permissionsService.loadPermissions(this.authService.permissions);
		// }
		// for (let parent = 0; parent < this.menuLinks.length; parent++) {
		//   if (!this.authService.hasPermission(this.menuLinks[parent].roles)) {
		//     this.menuLinks[parent] = null;
		//   }
		// }
		// this.menuLinks = this.menuLinks.filter(Boolean);
		let _this = this;
		if (!this.authService.permissions) {
			if (this.store.getItem('currentUser')) {
				this.authService.permissions = this.store.getItem('currentUser')['permissions'];
			}
		}
		if (this.authService.permissions) {
			this.permissionsService.loadPermissions(this.authService.permissions);
		}
		// let parentIdx = [];
		// for (let parent = 0; parent < this.menuLinks.length; parent++) {
		//   if (!this.authService.hasPermission(this.menuLinks[parent].roles)) {
		//     parentIdx.push(parent);
		//   }
		//   if(this.menuLinks[parent]['submenu']){
		//     let idxList = [];
		//     for(let child = 0; child < this.menuLinks[parent]['submenu'].length;child++){
		//       if (this.menuLinks[parent]['submenu'][child]['roles'] && !this.authService.hasPermission(this.menuLinks[parent]['submenu'][child]['roles'])) {
		//         idxList.push(child);
		//       }
		//     }
		//     idxList.forEach(index => {
		//       delete _this.menuLinks[parent]['submenu'][index];
		//     });
		//   }
		// }
		// parentIdx.forEach(index => {
		//   delete this.menuLinks[index];
		// })
		// this.menuLinks = this.menuLinks.filter(Boolean);
	}

	logout() {
		this.authService.signout();
	}

	isGraceMode() {
		if (!this.store.getItem('currentUser')) {
			return null;
		}
		return this.store.getItem('currentUser')['grace'];
	}

	isExpiredMode() {
		if (this.expiredModeHidden) {
			return null;
		}
		if (!this.store.getItem('currentUser')) {
			return null;
		}
		// if (this.store.getItem('currentUser')['company']['renewal']) {
		//   return this.store.getItem('currentUser')['company']['renewal']['expiry_date'];
		// }
		return null;
	}

	expiredModeHidden: boolean = false;
	hideExpiredMode() {
		this.expiredModeHidden = true;
		let updatedUser = this.store.getItem('currentUser');
		//updatedUser['company']['renewal'] = null;
		this.store.setItem('currentUser', updatedUser);
	}

	getUsername() {
		let user = this.store.getItem('currentUser');
		if (!user) {
			return "";
		}
		return user.first_name + ' ' + user.last_name;
	}

	getLogo() {
		return this.authService.getLogo();
	}

	getFavicon() {
		return this.authService.getFavicon();
	}

	isAuthenticated() {
		if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
			return false;
		}
		return this.authService.authenticated;
	}

	profile() {
		this.router.navigate(['/profile']);
	}

	companyProfile() {
		this.router.navigate(['/company/profile']);
	}

	subscriptionModalTrigger: EventEmitter<any> = new EventEmitter<any>();
	openSubscriptionModal() {
		this.subscriptionModalTrigger.emit(true);
	}

	cardModalTrigger: EventEmitter<any> = new EventEmitter<any>();
	openCardModal() {
		this.cardModalTrigger.emit(true);
	}

}
