import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class UnitModel {

    id: number;
    title:string;
    dimensions:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.dimensions = input.dimensions || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            dimensions: [input.dimensions,Validators.required],
        })
    }

    static getListRow(row: UnitModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}