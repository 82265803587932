import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class WorkTimeModel {

    id: number;
    title:string;
    dimensions:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.dimensions = input.dimensions || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            unit: [input.unit ? input.unit.id : null,Validators.required],
            stock_type: [input.stock_type ? input.stock_type.id : null,Validators.required],
            applies_on_global: [input.applies_on_global == 1 ? '1' : '0', Validators.required],
            minute_per_linear: [input.minute_per_linear,Validators.required],
		});
    }

    static getListRow(row: WorkTimeModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}