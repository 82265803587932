import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RoleService } from './role.service';
import * as $ from 'jquery';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActionModel, MessageService } from '../../@pages/components/message/message.service';
import { MenuNavService } from '../services/menu-navigation.service';

@Injectable()
export class RoleGuard implements CanActivate {
  returnUrl: string;

  constructor(
    private permissionService : NgxPermissionsService,
    private router: Router,
    private roleService : RoleService,
    private messageService : MessageService,
    private authService : AuthService,
    private menuService : MenuNavService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let _this = this;
    // console.log("Accessed Can Activate");
    // console.log(state.url.split('/')[1]);


    // if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
    //   return false;
    // }
    if ($('form').hasClass("ng-touched") && $('form').hasClass("blockAuto")) {
      return false;
    }

    if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
      //console.log(state.url);
      
    //   let actions: ActionModel = {
    //     onAccept: {
    //       function: (event) => {
    //         $('form').addClass('exempt');
    //         console.log(next['_routerState']['url']);
    //         console.log('/'+next.url[0].path);
    //         this.router.navigateByUrl(next['_routerState']['url']);
    //       },
    //       buttonText: "Yes"
    //     },
    //     onDecline: {
    //       function: (event) => {
    //       },
    //       buttonText: "No"
    //     }
    //   }
    //   this.messageService.sendNotification(`You may have unsaved data. Are you sure you'd like to discard it?`, 'intervention', actions,0);
    //   return false;

      // if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
      //   setTimeout(() => {
      //     $('form').removeClass('blockAuto');
      //   }, 500);
      //   return false;
      // }
    }

    if (next.data.permission == 'generate_report') {
      if (next.url.length == 0) {

        if(localStorage.getItem('activeMenu')){
          //this.menuService.navigateToPossibleRoute();
        }
        
      }
      return true;
    }

    if (!next.data.permission){
      return true;
    }

    if (!this.authService.hasPermission(next.data.permission)) {
      console.warn('Access Denied. Navigated to default route');
      let path = _this.roleService.getDefaultRoute(_this.authService.getUserRole());
      _this.router.navigate([path]);
      return false;
    }

    else if (this.permissionService.hasPermission(next.data.permission)) {
      return true;
    }
    
    else {
      console.log(`Permission/Role not recognized`);
      return false;
    }

    return true;

    // if (userRole) {
    //   if (userRole.routeAccess[state.url.split('/')[1]]) {
    //     return true;
    //   }
    //   console.warn('Access Denied. Navigated to default route');

    //   const defaultRoute = this.rolesService.getAccess(userRoleId).defaultRoute;
    //   this.router.navigate([`/${defaultRoute}`]);
    //   return false;
    // } else {
    //   console.log(`User roles: [${userRole}] not recognized`);
    //   return false;
    // }

  }
}
