import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../shared/services/utils.service';
import { FurnitureBuildData } from '../furniture.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { FurnitureService } from '../../../shared/services/furniture.service';
import { FurnitureModel } from '../../../shared/models/furniture.model';
import { FurnitureCategoryModel } from '../../../shared/models/furniture-category.model';
import { FurnitureCategoryService } from '../../../shared/services/furniture-category.service';
import { UploadModel } from '../../../shared/models/upload.model';
import { UploadService } from '../../../shared/services/upload.service';
import { StockService } from '../../../shared/services/stock.service';
import { PartService } from '../../../shared/services/part.service';
import { StockModel } from '../../../shared/models/stock.model';
import { PartModel } from '../../../shared/models/part.model';
import { TypeModel } from '../../../shared/models/type.model';
import { TypeService } from '../../../shared/services/type.service';


@Component({
	selector: 'app-furniture-detail',
	templateUrl: './furniture-detail.component.html',
	styleUrls: ['./furniture-detail.component.scss']
})
export class FurnitureDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	object: FurnitureModel;
	objectPart: object;
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('addPartModal', { static: true }) modalAddPart: ModalDirective;
	@ViewChild('deletePartModal', { static: true }) modalDeletePart: ModalDirective;
	errors: String[] = [];
	data = new FurnitureBuildData();
	module = this.data.module;
	form: FormGroup;
	_cat: FurnitureCategoryModel[] = [];
	_img: UploadModel[] = [];
	//items: PartModel[] = [];
	_part: PartModel[] = [];
	_stock: StockModel[] = [];
	_stocktype: TypeModel[] = [];
	index: number;
	//id: any[];
	selectedPartValue: string='';
	sTitle: string='';

	constructor(
		private service: FurnitureService,
		private router: Router,
		private furnitureCategoryService: FurnitureCategoryService,
		private partService: PartService,
	  	private stockService: StockService,
		private stockTypeService: TypeService,
		private uploadService: UploadService,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		public permissionService: PermissionService,
		
		
	) { }

	@ViewChild('editor', { static: false }) editor: EditorComponent;
	ngOnInit() {
		let _this = this;

		this.loadPart();		
		this.loadCat();
		this.loadImg();
		//this.loadStock();
		this.createForm({});
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}
		})
	}
	
	
	loadCat() {
		var _this = this;
		this.furnitureCategoryService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._cat.push(new FurnitureCategoryModel(uObject));
			}
		});
	}

	loadPart() {
		var _this = this;
		this.partService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._part.push(new PartModel(uObject));
			}
		});
	}

	
	
	loadItems() {
		var _this = this;
		this.partService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._stock.push(new StockModel(uObject));
			}
		});
	}

	loadStock() {
		var _this = this;
		this.stockService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._stock.push(new StockModel(uObject));
			}
		});
	}

	loadStockType() {
		var _this = this;
		this.stockTypeService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._stocktype.push(new TypeModel(uObject));
			}
		});
	}

	loadImg() {
		var _this = this;
		this.uploadService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._img.push(new UploadModel(uObject));
			}
		});
	}


	menuItems: any[] = [
		{ action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
	];


	handleButtonPress = (event) => {
		switch (event) {
			case "delete":
				this.modalDelete.show();
				break;
			default:
				console.log(event);
				break;
		}
	}


	incomingGroupEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPaginatedSelectValue(fieldName, value) {
		let _this = this;
		switch (fieldName) {
			case 'groups':
				_this.form.get('groups').setValue(value);
				break;
		}
	}

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;

	fileChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
	@ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
	fromDate: Date = new Date();
	toDate: Date = moment(new Date()).add(1, 'hour').toDate();
	forceRefresh(dateEvent) {
		let _this = this;
		Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
			_this.cdr.detectChanges();
		})
	}

	_disabledStartDate = (startValue) => {
		return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
	};

	_disabledEndDate = (endValue) => {
		return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
	};

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	stocks: FormArray;
	parts: FormArray;
	//items: FormArray;
	createForm(input, form?) {
		input = input || {};
		if (this) {//this condition is because when this function is called from utils.service, the property this does not working.
			form ? form : this.form = FurnitureModel.getFormGroup(input);
			this.parts = this.form.get('parts') as FormArray;
		}
	}

	selectedPart;
	selectedItems = this.loadItems();
	AddNewPart() {
		if (!this.selectedPart) return;
		const partChoose = this._part.filter(obj => obj.id == this.selectedPart)[0];		
		(<FormArray>this.form.get('parts')).push(FurnitureModel.getPartFormGroup({
			quantity : 1,
			part: partChoose.id,
			...partChoose
		}));
	}

	selectPartChange(event :any) {
		//this.selectedValue = this.service.get(this.parts)[0].title;
		this.selectedPartValue = event.target.value;
	}
	toDeleteIdx = -1;
	showDeletePartModal(part, index)
	{
		this.objectPart = part;
		this.toDeleteIdx = index;
		this.modalDeletePart.show();
	}

	deletePart(){
		//console.log(this.form.get('parts').value.filter( part => part.id != this.object.id));
		//this.form.get('parts').patchValue(this.form.get('parts').value.filter( part => part.id != this.object.id));
		
		(<FormArray>this.form.get('parts')).removeAt(this.toDeleteIdx);
		
		//this.Parts.removeAt(this.Parts.value.findIndex(Part => Part.id));
		//(<FormArray>this.form.get('parts')).removeAt(this.toDeleteIdx);
		//this.toDeleteIdx = -1;
	}
	
	openFileUpload(ele) {
		$(ele).click();
	}

	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	isTouched(controlName) {
		return Utils.isFormTouched(this.form, controlName);
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
		}

		checkLoadingSpinner();

		this.service.get(id).subscribe(res => {
			_this.object = res['data'];
			_this.createForm(_this.object);
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err + " HELLO");
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isLoading = false;
		});
	}

	

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}

	photoChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
				// _this.form.get('image').setValue(res.cardImageBase64);

				// _this.uploadService.create({data: res.cardImageBase64, file_type: res.fileType, file_ext : res.ext }).subscribe(uploadRes => {
				_this.uploadService.create({ 
					data: res.cardImageBase64, 
					file_type: res.ext, 
					file_ext: res.ext, 
					description: res.fileName 
				}).subscribe(uploadRes => {
					_this.form.get('image').setValue(uploadRes['data']['file_path']);
				}, err => {
					console.log(err);
				})

			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@Input() isLoading: boolean = false;


	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	save() {
		let _this = this;
		_this.isLoading = true;

		this.form.patchValue(this.form.value);
		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			
			if (res['data']) {
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				
				else {
					this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}


	getTotal() {
		return this.form.get('parts').value.reduce((acum, current ) => {
			return acum + (current.quantity * current.total_cost);
		}, 0);
	}

}
