import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.stanthonyUrl}/part`,{params:searchParams});
    }

    get(Id){
      return this.http.get(`${environment.stanthonyUrl}/part/`+Id);
    }

    edit(Id,formData){
      return this.http.put(`${environment.stanthonyUrl}/part/`+Id,formData);
    }

    create(formData){
      return this.http.post(`${environment.stanthonyUrl}/part`,formData);
    }

    delete(Id){
      return this.http.delete(`${environment.stanthonyUrl}/part/`+Id);
    }

	calculate(formData){
		return this.http.post(`${environment.stanthonyUrl}/part/calculate/`,formData);
	}

}
