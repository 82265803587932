import { Routes } from '@angular/router';
//Layouts
import { 
  //CondensedComponent,
  BlankComponent,
  //CorporateLayout,
  SimplyWhiteLayout,
  //ExecutiveLayout,
  //CasualLayout ,
  //BlankCasualComponent,
  //BlankCorporateComponent,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';

export const AppRoutes: Routes = [

  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_user'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/user/user.module').then(m => m.UserModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'customer',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_customer'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/customer/customer.module').then(m => m.CustomerModule),
    runGuardsAndResolvers: 'always'
  },
//   {
//     path: 'camino',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_camino'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/camino/camino.module').then(m => CaminoModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'group',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_group'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/user/groups/groups.module').then(m => GroupModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'advert',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_advert'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/advert/advert.module').then(m => AdvertModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'pilgrim-guide',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_pilgrim_guide'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/pilgrim-guide/pilgrim-guide.module').then(m => PilgrimGuideModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'static-page',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_static_page'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/static-pages/static-pages.module').then(m => StaticPageModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'certificate',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_static_page'//TODO - Change to view_certificate when permission gets added.
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/certificate/certificate.module').then(m => CertificateModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'device',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_device'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/device/device.module').then(m => DeviceModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'route',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_route'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/route/route.module').then(m => RouteModule),
//     runGuardsAndResolvers: 'always'
//   },
//   {
//     path: 'poitype',
//     canActivate: [AuthGaurd,RoleGuard],
//     data: {
//       permission:'view_route_poi_type'
//     },
//     component: SimplyWhiteLayout,
//     loadChildren: () => import('./view/route/poi-type/poi-type.module').then(m => POITypeModule),
//     runGuardsAndResolvers: 'always'
//   },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    loadChildren:() => import('./view/profile/profile.module').then(m => m.ProfileModule),//'./view/profile/profile.module#ProfileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'generate_report'
    },
    component: SimplyWhiteLayout,
    loadChildren:() => import('./view/dashboard/dashboard.module').then(m => m.DashboardModule),//'./view/dashboard/dashboard.module#DashboardModule',
    runGuardsAndResolvers: 'always'
  },
  ///////////////////////////////////////////////////////

  {
    path: 'furniture/part',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_part'//TODO - Change to view_parts when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/part/part.module').then(m => m.PartModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'door/part',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_part'//TODO - Change to view_parts when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/part/part.module').then(m => m.PartModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'door/stock',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_stock'//TODO - Change to view_stock when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/stock/stock.module').then(m => m.StockModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'furniture/stock',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_stock'//TODO - Change to view_stock when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/stock/stock.module').then(m => m.StockModule),
    runGuardsAndResolvers: 'always'
  },

  {
    path: 'furnitureparts',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_furniture'//TODO - Change to view_furniture when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/furniture/furniture.module').then(m => m.FurnitureModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'quote',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_quote'//TODO - Change to view_quote when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/quote/quote.module').then(m => m.QuoteModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'unit',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_unit'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/unit/unit.module').then(m => m.UnitModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'stock/type',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_stock_type'
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/stock/type/type.module').then(m => m.TypeModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'furniture/category',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_furniture_category'//TODO - Change to view_furniture-category when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/furniture/category/category.module').then(m => m.FurnitureCategoryModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'part/category',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_part_category'//TODO - Change to view_parts-category when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/part/category/category.module').then(m => m.PartCategoryModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'worktime',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_work_time'//TODO - Change to view_parts-category when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/worktime/worktime.module').then(m => m.WorkTimeModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'settings',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_settings'//TODO - Change to view_parts-category when permission gets added.
    },
    component: SimplyWhiteLayout,
    loadChildren: () => import('./view/settings/settings.module').then(m => m.SettingsModule),
    runGuardsAndResolvers: 'always'
  },



  // {
  //   path: 'timesheettype',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:'view_timesheet'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren:() => import('./view/timesheet/type/type.module').then(m => TypeModule),//'./view/dashboard/dashboard.module#DashboardModule',
  //   runGuardsAndResolvers: 'always'
  // },
  // {
  //   path: 'employee',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:'view_employee'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren:() => import('./view/employee/employee.module').then(m => EmployeeModule),//'./view/dashboard/dashboard.module#DashboardModule',
  //   runGuardsAndResolvers: 'always'
  // },
  // {
  //   path: 'workcategory',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:'view_project'
  //   },
  //   component: SimplyWhiteLayout,
  //   loadChildren:() => import('./view/works/works-categories/works-categories.module').then(m => WorksCategoryModule),//'./view/dashboard/dashboard.module#DashboardModule',
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: 'session',
    data: {
      permission:''
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  // {
  //   path: 'menuselect',
  //   pathMatch:'full',
  //   canActivate: [AuthGaurd,RoleGuard],
  //   data: {
  //     permission:''
  //   },
  //   component: SimplyWhiteLayout,
  //   runGuardsAndResolvers: 'always'
  // },
  {
    path: '**',
    redirectTo: 'quote',
  }
];
