import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { MessageService } from '../../@pages/components/message/message.service';
import { UserModel } from '../../shared/models/user.model';
import { Router, NavigationStart } from '@angular/router';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { LocalStoreService } from '../../shared/services/local-store.service';
import { Location } from '@angular/common';
import { MenuNavService } from '../../shared/services/menu-navigation.service';
import { Utils } from '../../shared/services/utils.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuSelectionComponent implements OnInit {

  @Input() external:boolean = false;
  menuItems: any[] = [
    {
      icon: 'fas fa-users',
      title:'Human Resources',
      identifier:'HR',
      backgroundColor:'#fff3e0',
      iconColor:"#FFAB00",
      innerMenuItems:[
        {
          label: 'Companies',
          iconType: 'fal',
          iconName: 'fa-building',
          routerLink: '/company',
          roles:'view_company',
        },
        {
          label: 'Clients',
          routerLink: '/client',
          iconType: 'fal',
          iconName: 'fa-user-tie',
          roles:'view_client',
        },
        {
          label: 'Administration',
          //routerLink: '/administration',
          iconType: 'fal',
          iconName: 'fa-users',
          roles:'view_user',
          routerLink: '/administration',
        },
        {
          label: 'Employees',
          routerLink: '/employee',
          iconType: 'fal',
          iconName: 'fa-user',
          roles:'view_employee',
        }
      ]
    },
    {
      icon: 'fas fa-briefcase',
      title:'Works',
      identifier:'WRK',
      backgroundColor:'#e0f7fa',
      iconColor:'#38CB89',
      innerMenuItems:[
        {
          label: 'Timesheets',
          iconType: 'fal',
          iconName: 'fa-user-clock',
          roles:'view_timesheet',
          routerLink: '/timesheet',
          // submenu: [
          //   {
          //     label: 'Timesheets',
          //     routerLink: '/timesheet',
          //     iconType: 'fal',
          //     iconName: 'fa-user-clock',
          //   },
          //   {
          //     label: 'Types',
          //     routerLink: '/timesheet/type',
          //     iconType: 'fal',
          //     iconName: 'fa-clock',
          //   }
          // ]
        },
        {
          label: 'Works',
          iconType: 'fal',
          iconName: 'fa-tools',
          routerLink: '/works',
          roles:'view_work',
          // submenu: [
          //   {
          //     label: 'Works',
          //     routerLink: '/works',
          //     iconType: 'fal',
          //     iconName: 'fa-tools',
          //   },
          //   {
          //     label: 'Categories',
          //     routerLink: '/works/category',
          //     iconType: 'fal',
          //     iconName: 'fa-toolbox',
          //   }
          // ]
        },
        {
          label: 'Projects',
          routerLink: '/project',
          iconType: 'fal',
          iconName: 'fa-building',
          roles:'view_project',
        },
        // {
        //   label: 'Works',
        //   routerLink: '/works',
        //   iconType: 'fal',
        //   iconName: 'fa-tools',
        //   roles:'view_work',
        // },
        // {
        //   label: 'Stock',
        //   routerLink: '/stock',
        //   iconType: 'fal',
        //   iconName: 'fa-boxes',
        //   roles:'view_stock',
        // },
        // {
        //   label: 'Works Categories',
        //   routerLink: '/workcategory',
        //   iconType: 'fal',
        //   iconName: 'fa-toolbox',
        //   roles:'view_work',
        // },
      ]
    },
    {
      icon: 'fas fa-cogs',
      title:'Setting',
      identifier:'STG',
      backgroundColor:'#c9e0ff',
      iconColor:'#5b7fff',
      innerMenuItems:[
        {
          label: 'Permissions',
          iconType: 'fal',
          iconName: 'fa-users',
          routerLink: '/group',
          roles:'view_group',
        },
        {
          label: 'Timesheet Types',
          routerLink: '/timesheet/type',
          iconType: 'fal',
          iconName: 'fa-clock',
          roles:'view_timesheet_type',
        },
        {
          label: 'Work Categories',
          routerLink: '/works/category',
          iconType: 'fal',
          iconName: 'fa-toolbox',
          roles:'view_works_category',
        }
      ]
    },
    {
      icon: 'fas fa-file-chart-line',
      title:'Reports',
      identifier:'RPT',
      backgroundColor:'#ffecbb',
      iconColor:'#ff5630',
      innerMenuItems:[
        {
          label: 'Dashboard',
          iconType: 'fal',
          iconName: 'fa-tachometer',
          routerLink: '/dashboard',
          roles:'generate_report',
        },
        // {
        //   label: 'Employee Hours',
        //   iconType: 'fal',
        //   iconName: 'fa-user',
        //   routerLink: '/report/employee',
        //   roles:'generate_report',
        // },
      ]
    }
  ];

  constructor(
    private menuService : MenuNavService,
    private utils : Utils,
    private router : Router,
    private authService : AuthService
  ) { }

  ngOnInit() {
    this.menuService.navigateToPossibleRoute();
    //this.runPermissionsCheck();
  }

  runPermissionsCheck(){
    let _this = this;
    outerLoop:for(let menuItem of this.menuItems){
      for(let subMenu of menuItem.innerMenuItems){
        if(_this.authService.hasPermission(subMenu.roles)){
          continue outerLoop;
        }
      }
      menuItem['hidden'] = true;
    }
  }

  selectMenu(item){
    let _this = this;

    if(this.menuService.menuItems && this.menuService.menuItems[0]){
      if(this.menuService.menuItems[0].label == item.innerMenuItems[0].label){
        return;
      }
    }

    this.menuService.menuItems = item.innerMenuItems;
    this.menuService.onMenuChange.emit(item.title);
    this.utils.successMessage("Menu changed to "+item.title);
    for(let menuIndex = 0;menuIndex < item.innerMenuItems.length;menuIndex++){
      if(_this.authService.hasPermission(item.innerMenuItems[menuIndex].roles)){
        if(item.innerMenuItems[menuIndex].routerLink){
          this.router.navigate([item.innerMenuItems[menuIndex].routerLink]);
        }
        else{
          this.router.navigate([item.innerMenuItems[menuIndex].submenu[menuIndex].routerLink]);
        }
        return;
      }
    }
    console.log("Permission Failure: No Permissions Found for any of the modules inside this menu.");
  }

}