import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UnitModel } from "./unit.model";

export class TypeModel {

    id: number;
    title:string;
    unit:UnitModel[];

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.unit = input.unit || [];
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            unit: [input.unit ? input.unit.id : null,Validators.required],
        })
    }

    static getListRow(row: TypeModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}