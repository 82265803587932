import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PartCategoryModel } from "./part-category.model";
import { UploadModel } from "./upload.model";

export class PartModel {

    id: number;
    title:string;
    description:string;
    image:UploadModel[];
	reference:string;
	profit_percentage:string;
    admin_percentage:string;
    labour_minute:string;
    total_cost:string;
    total_selling:string;
	category:PartCategoryModel[];
	items:any[];
	height?: number = null;
	width?: number = null;
	length?: number = null;


    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.description = input.description || '';
        this.image = input.image || [];
		this.reference = input.reference || '';
		this.profit_percentage = input.profit_percentage || '';
		this.admin_percentage = input.admin_percentage || '';
		this.labour_minute = input.labour_minute || '';
		this.total_cost = input.total_cost || '';
		this.total_selling = input.total_selling || '';
		this.category = input.category || [];
		this.items = input.items || [];

		this.height = input.height || null;
		this.width = input.width || null;
		this.length = input.length || null;

    }

	static getItemFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();
		if(items.stock instanceof Object)
		{
			items._stock = items.stock;
			items.stock = items._stock.id;
		}

		let ItemsFormArray = new FormArray([]);
		if (items.worktime) {
			for (let item of items.worktime) {
				ItemsFormArray.push(this.getWorkTimeFormGroup(item));
			}
		}

		let EdgingFormArray = new FormArray([]);
		if (items.edging) {
			for (let item of items.edging) {
				EdgingFormArray.push(this.getEdgingFormGroup(item));
			}
		}

		let SprayFormArray = new FormArray([]);
		if (items.spray) {
			for (let item of items.spray) {
				SprayFormArray.push(this.getSprayFormGroup(item));
			}
		}

		return fb.group({
			quantity: new FormControl(items ? items.quantity : 1),
			id: new FormControl(items ? items.id : ''),
			length: new FormControl(items ? items.length : ''),
			stock_type: new FormControl(items ? items._stock.type.id : ''),
			width: new FormControl(items ? items.width : ''),
			stock_total: new FormControl(items ? items.stock_total : ''),
			line_total: new FormControl(items ? items.line_total : ''),
			height: new FormControl(items ? items.height : ''),
			stock: new FormControl(items ? items.stock : {}),
			title: new FormControl(items ? items.title ? items.title : items._stock.title : {}),
			total_measurement : new FormControl(items ? items.total_measurement : ''),
			manual_measurement: new FormControl(items ? items.manual_measurement : 0),
			use_part_volume: new FormControl(items ? items.use_part_volume : 0),
			use_part_measurement: new FormControl(items ? items.use_part_measurement : 0),
			worktime: ItemsFormArray,
			spray: SprayFormArray,
			edging: EdgingFormArray,
			_stock: new FormControl(items ? items._stock : {})
		});
	}

	static getWorkTimeFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			id: new FormControl(items ? items.id : null , Validators.required),
			_object: items
		});
	}

	static getSprayFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			id: new FormControl(items && items.id ? items.id : null),
			spray: new FormControl(items && items.spray ? items.spray.id : null, Validators.required),
			work_time: new FormControl(items && items.work_time ? items.work_time.id : null, Validators.required),
			quantity: new FormControl(items && items.quantity ? items.quantity : null),
			_object: items
		});
	}

	static getEdgingFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			id: new FormControl(items && items.id ? items.id : null),
			title:  new FormControl(items && items.edging ? items.edging.title : null),
			edging: new FormControl(items && items.edging ? items.edging.id : null, Validators.required),
			work_time: new FormControl(items && items.work_time ? items.work_time.id : null, Validators.required),
			length: new FormControl(items ? items.length : null),
			width: new FormControl(items ? items.width : null),
			height: new FormControl(items ? items.height : null),
			manual_measurement: new FormControl(items && items.manual_measurement != null ? items.manual_measurement : false),
			total_measurement: new FormControl(items ? items.total_measurement : null),
			_object: items
		});
	}

	static getLabourMinFormGroup(items?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			worktime: new FormControl(items ? items.worktime : null, Validators.required),
			manual_value: new FormControl(items ? items.manual_value : 0),
			value: new FormControl(items ? items.value : 0),
			multiplier: new FormControl(items ? (items.multiplier == null ? 1 : items.multiplier) : 1),
			editTime: new FormControl(false),
		});
	}

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();

		let ItemsFormArray = new FormArray([]);
		if (input.items) {
			for (let item of input.items) {
				ItemsFormArray.push(this.getItemFormGroup(item));
			}
		}

		let LabourMinFormArray = new FormArray([]);
		if (input.labour_minute_breakdown) {
			for (let item of input.labour_minute_breakdown) {
				LabourMinFormArray.push(this.getLabourMinFormGroup(item));
			}
		}


        return fb.group({
            title: [input.title,Validators.required],
            description: [input.description],
			image: [input.image ? input.image : null],
			reference: [input.reference],
			profit_percentage: [input.profit_percentage, Validators.required],
			admin_percentage: [input.admin_percentage, Validators.required],
			labour_minute: [input.labour_minute],
			total_cost: [input.total_cost],
			total_selling: [input.total_selling],
			category: [input.category ? input.category.id : null, Validators.required],
			width: [input.width, Validators.required],
			height: [input.height, Validators.required],
			length: [input['length'], Validators.required],
			items: ItemsFormArray,
            used_for: [input.used_for],
			labour_minute_breakdown: LabourMinFormArray,
			labour_rate: 1
        })
    }

    static getListRow(row: PartModel) {
        return {
            id: row.id,
            title:row.title,
			reference:row.reference
        };
    }

}