import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MenuNavService {

  menuItems:any[] = [
	{
		label: 'Quotes',
		routerLink: '/quote',
		iconType: 'fal',
		iconName: 'fa-file',
		roles: 'view_quote',
	},
	{
		label: 'Furniture',
	 	routerLink: '/furnitureparts',
	 	iconType: 'fal',
	 	iconName: 'fa-couch',
	 	roles: 'view_furniture',
	},
	{
		label: 'Doors',
		routerLink: '/door/part',
		iconType: 'fal',
		iconName: 'fa-browser',
		roles: 'view_part',
	},
	{
		label: 'Furniture Part',
		routerLink: '/furniture/part',
		iconType: 'fal',
		iconName: 'fa-browser',
		roles: 'view_part',
	},
	{
		label: 'Doors Stock',
		routerLink: '/door/stock',
		iconType: 'fal',
		iconName: 'fa-box',
		roles: 'view_stock',
		
	},
	{
		label: 'Furniture Stock',
		routerLink: '/furniture/stock',
		iconType: 'fal',
		iconName: 'fa-box',
		roles: 'view_stock',
		
	},
	{
		label: 'Customer',
		//routerLink: '/administration',
		iconType: 'fal',
		iconName: 'fa-user',
		roles: 'view_customer',
		routerLink: '/customer',
	  },
	{
		label: 'Setting',
		iconType: 'fal',
		iconName: 'fa-angle-down',
		toggle: 'close',
		roles: 'view_group',
		submenu: [
			
			// {
			//   label: 'Furniture Category',
			//   routerLink: '/furniture/category',
			//   iconType: 'fal',
			//   iconName: 'fa-chair',
			//   roles: 'view_furniture_category'
			// },
			{
			  label: 'Part Category',
			  routerLink: '/part/category',
			  iconType: 'fal',
			  iconName: 'fa-hammer',
			  roles: 'view_part_category',
			},
			{
				label: 'Stock Type',
				routerLink: '/stock/type',
				iconType: 'fal',
				iconName: 'fa-sticky-note',
				roles: 'view_stock_type',
			},
			{
				label: 'Work Time',
				routerLink: '/worktime',
				iconType: 'fal',
				iconName: 'fa-clock',
				roles: 'view_work_time',
			  },
			{
				label: 'Unit',
				routerLink: '/unit',
				iconType: 'fal',
				iconName: 'fa-browser',
				roles: 'view_unit',
			},
			{
				label: 'User',
				iconType: 'fal',
				iconName: 'fa-users',
				roles: 'view_user',
				routerLink: '/user',
			},
			{
				label: 'Settings',
				routerLink: '/settings',
				iconType: 'fal',
				iconName: 'fa-cog',
				roles: 'view_settings',
			}
		]
	}

    
  ];
  onMenuChange:EventEmitter<any> = new EventEmitter<any>();

  navigateToPossibleRoute() {
    for (let menuItem of this.menuItems) {
      if (this.authService.hasPermission(menuItem.roles)) {
        this.router.navigate([menuItem.routerLink]);
        break;
      }
    }
  }

  constructor(
    private http : HttpClient,
    private router : Router,
    private authService : AuthService
  ) { }

    

}
