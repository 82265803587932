import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserModel } from "./user.model";

export class POITypeModel {

    id: number;
    title:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title]
        })
    }

    static getListRow(row: POITypeModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}