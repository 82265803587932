import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';
import { MessageService } from '../../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../../shared/services/utils.service';
import { GroupBuildData } from '../groups.build-data';
import { pgDatePickerComponent } from '../../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../../shared/services/permission-group.service';
import { PaginatedSelectComponent } from '../../../components/paginated-select/paginated-select.component';
import { GroupModel } from '../../../../shared/models/permission-group.service';

@Component({
  selector: 'app-group-detail',
  templateUrl: './groups-detail.component.html',
  styleUrls: ['./groups-detail.component.scss']
})
export class GroupDetailComponent implements OnInit {

  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  object: GroupModel;
  roles: any[];
  @ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
  errors: String[] = [];
  data = new GroupBuildData();
  module = this.data.module;
  form: FormGroup;

  constructor(
    private service: PermissionGroupService,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    public permissionService: PermissionService
  ) { }

  ngOnInit() {
    let _this = this;
    this.createForm({});
    this.loadPermissions();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.messageService.remove();
      }
    });
    this.snapshot.paramMap.subscribe(res => {
      if (res['params']['id'] && !_this.external) {
        this.loadData(res['params']['id']);
      }
    })
  }

  activePermissions: any[] = [];
  permissions: Map<string,any[]> = new Map<string,any[]>();
  permissionTitles:any = [];
  loadPermissions() {
    let _this = this;
    this.permissionService.getAll({ per_page: 10000 }).subscribe(res => {
      //_this.permissions = res['data'];
      for(let perm of res['data']){
        let replace = perm['slug_permission'].split("_")[0]+"_";
        let title = perm['slug_permission'].replace(replace,"").split("_").join(" ");
        if(_this.permissions.has(title)){
          _this.permissions.set(title,[..._this.permissions.get(title),perm]);
        } 
        else{
          _this.permissions.set(title,[perm]);
        } 
      }
      _this.permissionTitles = Array.from(_this.permissions.keys());
    },
      err => {
        console.log(err);
      })
  }

  hasPermissionActive(id) {
    //console.log(this.activePermissions);
    return this.activePermissions.includes(id);
  }

  menuItems: any[] = [
    { action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
  ];

  handleButtonPress = (event) => {
    switch (event) {
      case "delete":
        this.modalDelete.show();
        break;
      default:
        console.log(event);
        break;
    }
  }

  incomingEvent: EventEmitter<any> = new EventEmitter<any>();
  incomingPaginatedSelectValue(fieldName, value) {
    let _this = this;
    _this.form.get(fieldName).setValue(value);
  }

  markTouched() {
    this.form.markAsTouched();
  }

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  fileChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }

  @ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
  @ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
  fromDate: Date = new Date();
  toDate: Date = moment(new Date()).add(1, 'hour').toDate();
  forceRefresh(dateEvent) {
    let _this = this;
    Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
      _this.cdr.detectChanges();
    })
  }

  _disabledStartDate = (startValue) => {
    return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
  };

  _disabledEndDate = (endValue) => {
    return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
  };

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = GroupModel.getFormGroup(input);
  }

  openFileUpload(ele) {
    $(ele).click();
  }

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  toggle(id) {
    if (this.activePermissions.includes(id)) {
      this.activePermissions = this.activePermissions.map(val => {
        if (val != id) {
          return val;
        }
      });
      let removeUndefined = () => {
        let tmp = [];
        for (let perm of this.activePermissions) {
          if (perm) {
            tmp.push(perm);
          }
        }
        this.activePermissions = tmp;
      }
      removeUndefined();
    }
    else {
      this.activePermissions = [...this.activePermissions, id];
    }
  }

  isTouched(controlName) {
    return Utils.isFormTouched(this.form, controlName);
  }

  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadData(id) {
    let _this = this;
    let checkLoadingSpinner = () => {
      if (!_this.loading.isFirstLoad) {
        _this.loading.isFirstLoad = true;
        _this.loading.isLoading = true;
      }
    }

    checkLoadingSpinner();

    this.service.get(id).subscribe(res => {
      _this.object = res['data'];
      _this.object.permissions = _this.object.permissions.map(val => {
        return val.id
      });
      _this.activePermissions = _this.object.permissions;
      _this.createForm(_this.object);
      _this.triggerFetch.emit(_this.form);
      _this.loading.isLoading = false;
    },
      err => {
        _this.loading.isLoading = false;
        console.log(err);
      });
  }

  //Variable & Function for <phone-input>
  triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  updateForm(event) {
    this.form = event;
  }

  delete() {
    let _this = this;
    _this.isLoading = true;
    this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
      _this.isLoading = false;
    });
  }

  isFormValueChecked(controlName) {
    return this.form.get(controlName).value;
  }

  @Input() isLoading: boolean = false;


  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
  save() {
    let _this = this;
    _this.isLoading = true;
    _this.form.get('permissions').setValue(_this.activePermissions);
    Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
    Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
      _this.isLoading = false;
      if (res['data']) {
        if (_this.external == true) {
          _this.saveEvent.emit(res['data']);
        }
        else {
          this.router.navigate(['/' + this.module.new_name + '/' + res['data']['id']]);
        }
        _this.utils.successMessage(_this.module.name + ' saved.');
      }
      else {
        Utils.sendFormSaveError(res,this.module,this.utils);
      }
    })
  }


}
