import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class UploadModel {

    id: number;
    app:string;
    user:string;
	file_path:string;
	file_type:string;
	description:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.app = input.app || '';
        this.user = input.user || '';
		this.file_path = input.file_path || '';
		this.file_type = input.file_type || '';
		this.description = input.description || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            app: [input.app],
            user: [input.user],
			file_path: [input.file_path],
            file_type: [input.file_type],
			description: [input.description],
        })
    }

}