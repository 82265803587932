import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { Utils } from '../../../shared/services/utils.service';
import { StockBuildData } from '../stock.build-data';
import { pgDatePickerComponent } from '../../../@pages/components/datepicker/datepicker.component';
import * as moment from 'moment';
import { PermissionService } from '../../../shared/services/permission.service';
import { PaginatedSelectComponent } from '../../components/paginated-select/paginated-select.component';
import { StockModel } from '../../../shared/models/stock.model';
import { StockService } from '../../../shared/services/stock.service';
import { title } from 'process';
import { UnitService } from '../../../shared/services/unit.service';
import { UnitModel } from '../../../shared/models/unit.model';
import { TypeModel } from '../../../shared/models/type.model';
import { TypeService } from '../../../shared/services/type.service';
import { UploadModel } from '../../../shared/models/upload.model';
import { UploadService } from '../../../shared/services/upload.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-stock-detail',
	templateUrl: './stock-detail.component.html',
	styleUrls: ['./stock-detail.component.scss']
})
export class StockDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	object: StockModel;
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	errors: String[] = [];
	data = new StockBuildData();
	module = this.data.module;
	form: FormGroup;
	_un : UnitModel[] = [];
	_type : TypeModel[] = [];
	_img : UploadModel[] = [];
	used_for = '';

	constructor(
		private service: StockService,
		private router: Router,
		private unitSerivce: UnitService,
		private typeService: TypeService,
		private uploadService: UploadService,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private location: Location,
		private cdr: ChangeDetectorRef,
		public permissionService: PermissionService
	) { }

	ngOnInit() {
		if(this.location.path().indexOf('/furniture/stock') > -1)
		{
			this.used_for = 'furniture';
		}
		else
		{
			this.used_for = 'doors';
		}
		let _this = this;
		//this.createCPU({});
		this.loadUnits();
		this.loadType();
		this.loadImg();
		this.createForm({});
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}
		});

		
	}

	loadUnits()	{
		var _this = this;
		this.unitSerivce.getAll({}).subscribe(res => {
			for(var uObject of res['data'])
			{
				_this._un.push(new UnitModel(uObject));
			}
		});
	}

	loadType()	{
		var _this = this;
		this.typeService.getAll({}).subscribe(res => {
			for(var uObject of res['data'])
			{
				_this._type.push(new TypeModel(uObject));
			}
		});
	}

	loadImg() {
		var _this = this;
		this.uploadService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._img.push(new UploadModel(uObject));
			}
		});
	}


	menuItems: any[] = [
		{ action: 'delete', message: 'Delete ' + this.module.name, class: '', icon: 'fal fa-trash', permissions: ['delete_' + this.module.permission] }
	];

	handleButtonPress = (event) => {
		switch (event) {
			case "delete":
				this.modalDelete.show();
				break;
			default:
				console.log(event);
				break;
		}
	}


	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;

	fileChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@ViewChild('fromPicker', { static: true }) fromPicker: pgDatePickerComponent;
	@ViewChild('toPicker', { static: true }) toPicker: pgDatePickerComponent;
	fromDate: Date = new Date();
	toDate: Date = moment(new Date()).add(1, 'hour').toDate();
	forceRefresh(dateEvent) {
		let _this = this;
		Utils.forceRefresh(dateEvent, this.fromDate, this.toDate, this.fromPicker, this.toPicker).subscribe(res => {
			_this.cdr.detectChanges();
		})
	}

	_disabledStartDate = (startValue) => {
		return Utils._disabledStartDate(startValue, this.fromDate, this.toDate);
	};

	_disabledEndDate = (endValue) => {
		return Utils._disabledEndDate(endValue, this.fromDate, this.toDate);
	};

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	createForm(input, form?) {
		input = input || {};
		input.used_for = this.used_for;
		form ? form : this.form = StockModel.getFormGroup(input);

		if(form)
		{
			return form;
		}
	}


	incomingGroupEvent: EventEmitter<any> = new EventEmitter<any>();
	incomingPaginatedSelectValue(fieldName, value) {
	  let _this = this;
	  switch (fieldName) {
		case 'groups':
		  _this.form.get('groups').setValue(value);
		  break;
	  }
	}

	openFileUpload(ele) {
		$(ele).click();
	}

	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	isTouched(controlName) {
		return Utils.isFormTouched(this.form, controlName);
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
		}

		checkLoadingSpinner();

		this.service.get(id).subscribe(res => {
			_this.object = res['data'];
			_this.createForm(_this.object);
			//_this.createCPU(_this.object);
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
			if (res['data']['groups']) {
				for (let permGroup of res['data']['groups']) {
				  _this.incomingGroupEvent.emit(permGroup);
				}
			  }
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err);
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.object.id, this.module.name, "/" + this.module.new_name).subscribe(res => {
			_this.isLoading = false;
		});
	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}

	photoChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
				// _this.form.get('image').setValue(res.cardImageBase64);

				// _this.uploadService.create({data: res.cardImageBase64, file_type: res.fileType, file_ext : res.ext }).subscribe(uploadRes => {
				_this.uploadService.create({ 
					data: res.cardImageBase64, 
					file_type: res.ext, 
					file_ext: res.ext, 
					description: res.fileName 
				}).subscribe(uploadRes => {
					_this.form.get('image').setValue(uploadRes['data']['file_path']);
				}, err => {
					console.log(err);
				})

			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

	@Input() isLoading: boolean = false;


	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	save() {
		let _this = this;
		_this.isLoading = true;

		Utils.forceTouchPaginatedSelects(this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.object, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			if (res['data']) {
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					if(_this.used_for == 'doors')
					{
						this.router.navigate(['/door/stock/' + res['data']['id']]);
					}
					else {
						this.router.navigate(['/furniture/stock/' + res['data']['id']]);

					}
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}


}
