import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class SettingsModel {

    id: number;
    key:string;
    value:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.key = input.key || '';
        this.value = input.value || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            key: [input.key],
            value: [input.value],
        })
    }

    static getListRow(row: SettingsModel) {
        return {
            id: row.id,
            key:row.key
        };
    }

}