import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FurnitureService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.stanthonyUrl}/furniture`,{params:searchParams});
    }

    get(Id){
      return this.http.get(`${environment.stanthonyUrl}/furniture/`+Id).pipe(
        map(deatilsFurniture => {
          const castData = {
            ...deatilsFurniture,
            data: {
              ...deatilsFurniture['data'],
              parts: deatilsFurniture['data']['parts'].map(part => {
                return {
                  ...part,
                  title: part.part.title,
                  image: part.part.image
                }
              })
            }
          }
          return castData;
        })
      );
    }

    edit(Id,formData){
      const castData = {
        ...formData,
        parts: formData['parts'].map(part => {
          return {
            ...part,
            part: part.part.id,
          }
        })
      }
      return this.http.put(`${environment.stanthonyUrl}/furniture/`+Id,castData);
    }

    create(formData){
      return this.http.post(`${environment.stanthonyUrl}/furniture`,formData);
    }

    delete(Id){
      return this.http.delete(`${environment.stanthonyUrl}/furniture/`+Id);
    }

	calculate(formData){
		return this.http.post(`${environment.stanthonyUrl}/furniture/calculate`,formData);
	  }

}
