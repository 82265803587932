import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'group-parts-item',
  templateUrl: './group-parts-item.component.html',
  styleUrls: ['./group-parts-item.component.scss']
})
export class GroupPartsItemComponent {

  allParts = [];
  allOrders = [];
  @Input() groupName: string;
  @Input() groupOrder: number;
  @Input('groupMaxOrder') set groupMaxOrder(maxOrder) {
	this.allOrders = Array(maxOrder).fill(0).map((x,i)=>i);
  };
  @Input() groupQuantity: number;
  @Input() totalByGroup: number;
  @Input('groupParts') set groupParts(allItmes: Array<any>) {
    this.allParts = allItmes;
  }

  @Input() indexGroup: number = null;
  @Output() removePart: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeGroupByIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() addPartToThisGroup: EventEmitter<any> = new EventEmitter<any>();
  @Output() addEmptyPartToThisGroup: EventEmitter<any> = new EventEmitter<any>();
  @Output() cloneThisGroup: EventEmitter<any> = new EventEmitter<any>();
  @Output() listenCalculatePrice: EventEmitter<any> = new EventEmitter<any>();
  @Output() listenChangeName: EventEmitter<any> = new EventEmitter<any>();
  @Output() listenChangeOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() listenChangeQuantity: EventEmitter<any> = new EventEmitter<any>();
  @Output() listenChangeGroupQuantity: EventEmitter<any> = new EventEmitter<any>();
  remove(indxPart) {
    this.removePart.emit({ groupIndex: this.indexGroup, partIndex: indxPart });
  }
  addPart() {
    this.addPartToThisGroup.emit({ groupIndex: this.indexGroup });
  }
  addEmptyPart() {
    this.addEmptyPartToThisGroup.emit({ groupIndex: this.indexGroup });
  }
  clonePart() {
    this.cloneThisGroup.emit({ groupIndex: this.indexGroup });
  }
  removeGroup() {
    this.removeGroupByIndex.emit({ groupIndex: this.indexGroup })
  }
  changeName() {
    this.listenChangeName.emit({ groupIndex: this.indexGroup, name: this.groupName });
  }

  calculatePrice() {
    this.listenCalculatePrice.emit({ groupIndex: this.indexGroup, name: this.groupName });
  }
  changeOrder() {
    this.listenChangeOrder.emit({ groupIndex: this.indexGroup, order: this.groupOrder });
  }
  changeQuatity(indexItem, event) {
    if(event.target.value === null || event.target.value === '') return;
    this.listenChangeQuantity.emit({ groupIndex: this.indexGroup, partIndex: indexItem, quantity: event.target.value });
  }

  changeQuatityGroup(event) {
    if(event.target.value === null || event.target.value === '') return;
    this.listenChangeGroupQuantity.emit({ groupIndex: this.indexGroup, quantity: event.target.value });
  }
}
