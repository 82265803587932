import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Utils } from '../../../shared/services/utils.service';
@Component({
  selector: 'phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() control: string;
  @Input() is_required: boolean = false;
  @Input() title:string;
  //@Output() onValueChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Input() triggerFetch: EventEmitter<FormGroup>;
  value:any;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Malta];
  @ViewChild('phoneInput',{static:false}) phoneComponent:NgxIntlTelInputComponent

  constructor(
    private fb: FormBuilder,
    private utils: Utils
  ) { }

  ngOnInit() {
    let _this = this;
    if (_this.form && _this.triggerFetch) {
      _this.triggerFetch.subscribe(res => {
        _this.value = res.get(_this.control).value;
      })
    }
  }

  clearPhoneData(countryData){
    this.phoneComponent.phoneNumber = null;
    this.phoneComponent.value = null;
    this.phoneComponent.selectedCountry = countryData;
    this.phoneComponent.selectedCountryISO = countryData['iso2'];
    this.phoneComponent.init();
  }
 
  formatPhoneData(event) {
    if(event){
      if(event['e164Number']){
        this.form.get(this.control).setValue(event['e164Number']);
      }
      else{
        this.form.get(this.control).setValue(event);
      }
    }
  }

  onChange(newVal) {
    if(newVal instanceof Object){
      this.formatPhoneData(newVal);
    }
  }

  setTouched() {
    this.form.get(this.control).markAsTouched();
    this.form.get(this.control).updateValueAndValidity();
  }

  isTouched() {
    return Utils.isFormTouched(this.form, this.control);
  }

  isControlRequired = (): boolean => {
    try {
      const validator = this.form.get(this.control).validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    catch (Err) {

    }
    return false;
  };

}