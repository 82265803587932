import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class PartCategoryModel {

    id: number;
    title:string;
    description:string;
	reference:string;
	parent:string;

    constructor(input?) {
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.parent = input.parent || '';
		this.reference = input.reference || '';
		this.description = input.description || '';
    }

    static getFormGroup(input): FormGroup {
        let fb: FormBuilder = new FormBuilder();
        return fb.group({
            title: [input.title,Validators.required],
            description: [input.description],
			reference: [input.reference],
			parent: [input.parent],
        })
    }

    static getListRow(row: PartCategoryModel) {
        return {
            id: row.id,
            title:row.title
        };
    }

}